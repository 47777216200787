import styled, { css } from 'styled-components';

// Import components
import { NavLink as RouterNavLink } from 'react-router-dom';

// Import utilties
import { customResponsive, size } from 'components/styles';

export const List = styled.ul`
	list-style: none;
	display: flex;
	${({ dropdown }) =>
		dropdown &&
		css`
			${customResponsive(
				size.medium,
				css`
					display: none;
				`
			)}
		`}
`;

export const Item = styled.li`
	display: flex;
	position: relative;
`;

export const NavLink = styled(RouterNavLink)`
	&:link,
	&:visited {
		position: relative;
		display: flex;
		align-items: center;
		padding: 0 2.7rem;
		color: ${({ theme: { search } }) => search.nav.menuText};
		font-size: ${({ theme }) => theme.fontSize.xs}rem;
		font-weight: ${({ theme }) => theme.font.bold};
		line-height: 3.2rem;
		transition: all 0.3s ease-in-out;
	}

	&.active-nav {
		&:link,
		&:visited {
			color: ${({ theme: { search } }) => search.nav.menuActiveText};
			&::after {
				transform: scaleX(1);
			}
		}
	}

	&:hover,
	&:focus-visible {
		color: ${({ theme: { search } }) => search.nav.menuHoverText};
	}
`;
