import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import { NavLink as RouterNavLink, Link as RouterLink } from 'react-router-dom';

// Import utilties
import { customMinResponsive, size } from 'components/styles';

const LIST_MODIFIERS = {
	searchPage: () => css`
		padding-top: 0.8rem;
	`
};

const ITEM_MODIFIERS = {
	searchPage: () => css`
		padding: 0 1.6rem;
	`
};

const NAV_LINK_MODIFIERS = {
	searchPage: ({ theme }) => css`
		&:link,
		&:visited {
			padding: 0.9rem;
			font-size: 1.6rem;

			&::after {
				height: 2px;
			}

			&:hover {
				color: ${theme.white};
			}
		}
	`
};
const LINK_MODIFIERS = {
	linkAsButton: ({ theme, padding, width }) => css`
		&:link,
		&:visited {
			display: inline-block;
			width: ${width ? width : 'auto'};
			font-size: ${theme.fontSize.xs}rem;
			font-weight: ${theme.font.bold};
			line-height: 4.6rem;
			text-align: center;
			border-radius: ${({ theme }) => theme.globalButtons.roundSize}rem;
			padding: ${padding ? `0 ${padding}rem` : '0 4rem'};
			background-color: ${theme.globalButtons.secondary.bgColor};
			border: 2px solid ${theme.globalButtons.secondary.bgColor};
			color: ${theme.globalButtons.secondary.color};
		}

		&:hover {
			border-color: ${theme.globalButtons.secondary.hoverBorderColor};
			background-color: ${theme.globalButtons.secondary.hoverBgColor};
		}
		&:focus-visible {
			border-color: ${theme.globalButtons.secondary.focusBorderColor};
			background-color: ${theme.globalButtons.secondary.focusBgColor};
		}
	`
};

const linkStyle = css`
	text-transform: capitalize;
	&:link,
	&:visited {
		position: relative;
		display: flex;
		align-items: center;
		color: ${({ theme: { body } }) => body.nav.color};
		font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
		transition: all 0.3s ease-in-out;

		${customMinResponsive(
			size.medium,
			css`
				padding: 0 0.7rem;
			`
		)}

		${customMinResponsive(
			size.large,
			css`
				padding: 0 3rem;
			`
		)}
	}

	&.active-nav {
		&:link,
		&:visited {
			&::after {
				content: '';
				display: block;
				width: 6px;
				height: 6px;
				position: absolute;
				bottom: -60%;
				left: 50%;
				transform: translateX(-50%);
				border-radius: 50px;
				background-color: ${({ theme: { body } }) =>
					body.nav.menuActiveColorDot};
			}
		}
	}

	&:hover {
		color: ${({ theme }) => theme.body.nav.hover};
	}

	&:hover .active-nav {
		color: ${({ theme }) => theme.body.nav.hover};
	}

	&:focus-visible {
		color: ${({ theme }) => theme.body.nav.hover};
	}

	&:focus-visible .active-nav {
		color: ${({ theme }) => theme.body.nav.hover};
	}
`;

const List = styled.ul`
	list-style: none;
	display: flex;

	${applyStyleModifiers(LIST_MODIFIERS)};
`;

const Item = styled.li`
	display: flex;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);
		height: 2.7rem;
		width: 1px;
		background-color: white;
	}
	${applyStyleModifiers(ITEM_MODIFIERS)};
`;

const NavLink = styled(RouterNavLink)`
	${linkStyle}
	${applyStyleModifiers(NAV_LINK_MODIFIERS)};
`;

const AnchorLink = styled.a`
	${linkStyle}
	${applyStyleModifiers(NAV_LINK_MODIFIERS)};
`;

const Link = styled(RouterLink)`
	${linkStyle}
	${applyStyleModifiers(LINK_MODIFIERS)};
`;

export { List, Item, NavLink, Link, AnchorLink };
