import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const Wrapper = styled.div`
	flex: 1;
	${flex({ jc: 'space-between', ai: 'flex-start' })};
`;

export const Container = styled.div`
	${flex()};
`;

export const MenusContainer = styled.div`
	background: ${({ theme }) => theme.body.nav.menuMobileBgColor};
	position: fixed;
	top: 7rem;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 999;
	overflow-y: auto;
	padding-bottom: 13rem;
`;
