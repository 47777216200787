import i18n from 'i18next';
import getSlug from 'speakingurl';

// Import paths
import { MAIN_PATHS } from 'components/routes/paths';

// Import variables
import { PRODUCTS_TYPES, DATE_FORMAT, SECTIONS_TYPES } from './variables';

// Import helpers
import { SUBSCRIPTION_STATUS_TYPES } from 'helpers/prices';
import { getFormatedDate, createLinkParams, convertTime } from 'helpers';
import { isProgramLive } from './epg';

const {
	VOD,
	AUDIO,
	COLLECTION,
	PACKET,
	ARTICLE,
	LIVE_EVENT,
	SEASON,
	EPISODE,
	SERIES,
	BANNER,
	PASS,
	CHANNEL
} = PRODUCTS_TYPES;
const { ACTIVE, CANCELED, PENDING } = SUBSCRIPTION_STATUS_TYPES;
const { DAY_MONTH_YEAR_FORMAT } = DATE_FORMAT;
const { CHANNEL_EPG } = SECTIONS_TYPES;

export const getProductTypeAlias = ({
	subtype,
	type,
	is_audio = false,
	context = {}
}) => {
	const { isCollection } = checkProductSubtype(subtype);
	const productType = isCollection ? subtype : type;
	const isAudio = context?.is_audio || is_audio;

	const isAudioType = productType === VOD && isAudio;

	const translationKey = isAudioType ? AUDIO : productType;

	if (type === CHANNEL) return i18n.t('common_general_channel');

	return i18n.t(`vod_product_type_alias_${translationKey}`);
};

export const getCurrentChannelProgramUuid = ({
	channels,
	currentChannelUuid
}) => {
	const currentChannel = channels?.find(
		({ uuid }) => uuid === currentChannelUuid
	);

	const currentProgram = currentChannel?.epgProgrammes.find((program) =>
		isProgramLive(program)
	);

	return currentProgram?.uuid;
};

export const getAuxiliaryProductData = ({
	product,
	pageName,
	sectionType = '',
	channels
}) => {
	const { images, title, type, subtype, uuid, since, till, context, genres } =
		product;

	// sometimes api returns since till values in context
	const sinceValue = since || context?.since;
	const tillValue = till || context?.till;

	const isLive = type === PRODUCTS_TYPES.LIVE_EVENT;
	const isSectionChannelEpg = sectionType === CHANNEL_EPG;
	const isChannel = type === PRODUCTS_TYPES.CHANNEL;

	const currentChannelProgramUuid =
		isChannel &&
		channels &&
		getCurrentChannelProgramUuid({ channels, currentChannelUuid: uuid });

	// we need this hash to display play button on program details if program is live
	const hash =
		isSectionChannelEpg &&
		isProgramLive({ since: sinceValue, till: tillValue }) &&
		'live';

	// sections with epg don't have subtypes
	const linkType = isSectionChannelEpg ? CHANNEL_EPG : subtype;

	const id = isChannel ? currentChannelProgramUuid : uuid;

	const url = context?.url_pc;

	const linkParams = createLinkParams({
		id,
		name: pageName,
		type: linkType,
		title,
		hash,
		url
	});

	const productGenres =
		genres
			?.map((genre) => genre?.name || genre)
			.splice(0, 2)
			.join(', ') || '';

	const getTime = () =>
		sinceValue && tillValue ? convertTime(sinceValue, tillValue) : '';

	const separator = productGenres.length ? '|' : '';

	const getLiveInfo = () => {
		if (isLive) {
			const time = getTime();
			return time ? ` ${separator} ${time}` : '';
		}
		return '';
	};

	const description = `${productGenres} ${getLiveInfo()}`;

	const availabilityDate = isSectionChannelEpg && getTime();

	return {
		images,
		linkParams,
		title,
		description,
		availabilityDate
	};
};

export const getProductDetailsApi = ({ id, type }) => {
	switch (type) {
		case ARTICLE:
		case LIVE_EVENT:
		case PASS:
			return `/products/${id}/details`;

		case COLLECTION:
		case PACKET:
			return `/products/packet/${id}`;

		default:
			return `/products/${type}/${id}`;
	}
};

export const checkProductSubtype = (subtype) => {
	const isCollection = subtype === COLLECTION;
	const isPacket = subtype === PACKET;
	const isArticle = subtype === ARTICLE;
	const isVod = subtype === VOD;
	const isLiveEvent = subtype === LIVE_EVENT;
	const isSeason = subtype === SEASON;
	const isEpisode = subtype === EPISODE;
	const isSeries = subtype === SERIES;
	const isBanner = subtype === BANNER;
	const isPass = subtype === PASS;

	return {
		isCollection,
		isPacket,
		isArticle,
		isVod,
		isLiveEvent,
		isSeason,
		isEpisode,
		isSeries,
		isBanner,
		isPass
	};
};

export const PRODUCT_TYPES_MAPPED = {
	[VOD]: 'vod',
	[AUDIO]: AUDIO,
	[ARTICLE]: 'artykul',
	artykul: ARTICLE,
	[COLLECTION]: 'kolekcja',
	kolekcja: COLLECTION,
	[LIVE_EVENT]: 'live',
	live: LIVE_EVENT,
	[SERIES]: 'serial',
	serial: SERIES,
	[SEASON]: 'sezon',
	sezon: [SEASON],
	[EPISODE]: 'odcinek',
	odcinek: EPISODE,
	seriale: SERIES,
	[PACKET]: 'pakiet',
	pakiet: PACKET,
	movies: 'vod',
	karnet: PASS,
	[PASS]: 'karnet'
};

export const PRODUCT_LIMIT_TYPES = {
	VIEWS: 'views',
	PURCHASES: 'purchases'
};

export const getSubscriptionStatus = (subscription) => {
	if (!subscription) return '';

	const { status, next_checkout_date, expiration_date } = subscription;

	const checkoutDate = next_checkout_date ?? expiration_date;
	const date = getFormatedDate(checkoutDate, DAY_MONTH_YEAR_FORMAT);

	switch (status) {
		case ACTIVE:
			return i18n.t('subscription_automatic_renewal', { date });

		case CANCELED:
			return i18n.t('user_profile_subsciption_canceled', { date });

		case PENDING:
			return i18n.t('user_profile_subsciption_pending');

		default:
			return '';
	}
};

export const getPaymentLink = ({
	id,
	type,
	title,
	priceId,
	isSubscriptionUpdate = false,
	provider
}) => {
	const pathname = MAIN_PATHS.PAYMENT.replace(':id', id)
		.replace(':type', PRODUCT_TYPES_MAPPED[type])
		.replace(':title', getSlug(title));

	return {
		pathname,
		state: {
			isPaymentPossible: true,
			priceId,
			update_subscription: isSubscriptionUpdate,
			provider
		}
	};
};

export const getProductType = (path) => {
	const { 3: type } = path.split('/');
	return type ?? null;
};
