import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { CommonButton } from 'components/elements';

const PassAvailableButton = () => {
	const { t } = useTranslation();

	return (
		<CommonButton isDisabled style={{ opacity: 0.8 }}>
			{t('vod_buttons_pass_available')}
		</CommonButton>
	);
};

export default PassAvailableButton;
