/**
 * returns poster image or error image
 * @param {object} images
 * @return {*}
 */
export function coverImage(images) {
	if (
		typeof images === 'object' &&
		!!images &&
		images.cover &&
		images.cover.length > 0 &&
		!!images.cover[0].url
	) {
		return images.cover[0].url;
	}

	return false;
}
