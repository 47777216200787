import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import FooterNav from './FooterNav';
import FooterSocial from './FooterSocial';
import FooterStores from './FooterStores';
import Copyrights from './Copyrights';
import Languages from './Languages';
import PaymentInfo from './PaymentInfo';

// Import styles
import {
	FooterStyled,
	Wrapper,
	InnerContainer,
	FlexContainer,
	StyledLogo,
	NavWrapper,
	AppVersion
} from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { FOOTER } = SECTIONS_ID;

const Footer = () => {
	const { t } = useTranslation();
	const showLanguages = false;

	return (
		<FooterStyled id={FOOTER}>
			<Wrapper>
				<FlexContainer>
					<NavWrapper>
						<StyledLogo
							aria-hidden="true"
							title={t('common_footer_zsw_logo_title')}
						/>
						<FooterNav />
					</NavWrapper>
					<FooterSocial />
					<InnerContainer>
						{showLanguages && <Languages />}
						<PaymentInfo />
						<FooterStores />
					</InnerContainer>
				</FlexContainer>
				<Copyrights />
			</Wrapper>

			<AppVersion>
				{t('common_app_version', { version: process.env.REACT_APP_VERSION })}
			</AppVersion>
		</FooterStyled>
	);
};

export default Footer;
