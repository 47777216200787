import { parseFontSize } from 'helpers';

const FONT_SIZE_KEYS = { xs: 'xs', s: 's', md: 'md', lg: 'lg', xl: 'xl' };

const { xs, s, md, lg, xl } = FONT_SIZE_KEYS;

const FONT_SIZE_TYPES = { [xs]: 1.6, [s]: 2, [md]: 2.4, [lg]: 3.6, [xl]: 4.8 };

const setSize =
	(size) =>
	(type = xs, num = 0) => {
		if (!FONT_SIZE_KEYS[type]) {
			throw new Error('Invalid font size type in setSize theme function');
		}

		const numSize = FONT_SIZE_TYPES[type] + num;
		const fontSize = numSize * size;
		const parsedFontSize = parseFontSize(fontSize);
		return Number(parsedFontSize.toFixed(1));
	};

export const setFontSize = (size = 1) => ({
	fontSize: {
		set: setSize(size),
		// Below are the default font size properties
		// In some cases in the app there are custom font sizes mixed with defaults
		xs: parseFontSize(FONT_SIZE_TYPES[xs] * size),
		s: parseFontSize(FONT_SIZE_TYPES[s] * size),
		md: parseFontSize(FONT_SIZE_TYPES[md] * size),
		lg: parseFontSize(FONT_SIZE_TYPES[lg] * size),
		xl: parseFontSize(FONT_SIZE_TYPES[xl] * size),

		product: {
			media: {
				title: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[md] * size)
				},
				description: parseFontSize(1.4 * size),
				productType: {
					mobile: parseFontSize(0.8 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
				},
				availabilityDate: {
					mobile: parseFontSize(1 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
				}
			}
		},
		wcagNav: {
			text: parseFontSize(1.3 * size),
			media: { text: parseFontSize(1.2 * size) }
		},
		nav: {
			link: parseFontSize(FONT_SIZE_TYPES[s] * size),
			logOutBtn: parseFontSize(FONT_SIZE_TYPES[s] * size),
			menuMobileItem: parseFontSize(1.6 * size),
			submenuMobileItem: parseFontSize(1.4 * size)
		},
		footer: {
			media: {
				link: parseFontSize(1.4 * size),
				text: {
					mobile: parseFontSize(1.1 * size),
					desktop: parseFontSize(0.9 * size)
				}
			}
		},
		productDetails: {
			title: {
				mobile: parseFontSize(2.6 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xl] * size)
			},
			basicItem: {
				mobile: parseFontSize(1.2 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			link: {
				mobile: parseFontSize(1.2 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			sectionDescription: { text: parseFontSize(1.4 * size) },
			description: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(2.2 * size)
			},
			subinformations: {
				title: {
					mobile: parseFontSize(2.6 * size),
					desktop: parseFontSize(3.8 * size)
				},
				description: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(2.2 * size)
				},
				shortDescription: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(2.2 * size)
				}
			},
			article: {
				author: parseFontSize(1.8 * size),
				media: { author: parseFontSize(1.2 * size) }
			},
			statute: {
				text: parseFontSize(FONT_SIZE_TYPES[xs] * size),
				link: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			subscription: {
				mobile: parseFontSize(1.6 * size),
				desktop: parseFontSize(2.2 * size)
			},
			tabButton: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(1.6 * size)
			},
			gallery: {
				author: {
					mobile: parseFontSize(1.2 * size),
					desktop: parseFontSize(1.8 * size)
				},
				title: {
					mobile: parseFontSize(1.2 * size),
					desktop: parseFontSize(1.8 * size)
				},
				assetNumber: {
					mobile: parseFontSize(1.2 * size),
					desktop: parseFontSize(1.8 * size)
				},
				description: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(2.6 * size)
				}
			},
			season: {
				title: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(2.4 * size)
				},
				noEpisodeInfo: parseFontSize(2.2 * size)
			},
			packetsModal: {
				heading: {
					mobile: parseFontSize(1.8 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[md])
				},
				item: {
					heading: {
						mobile: parseFontSize(1.8 * size),
						desktop: parseFontSize(FONT_SIZE_TYPES[md] * size)
					},
					description: parseFontSize(1.4 * size)
				}
			}
		},
		user: {
			logOutBtn: parseFontSize(FONT_SIZE_TYPES[xs] * size),
			nav: { link: parseFontSize(FONT_SIZE_TYPES[xs] * size) },
			media: { heading: parseFontSize(2.2 * size) },
			mainHeading: parseFontSize(3.4 * size),
			heading: parseFontSize(FONT_SIZE_TYPES[md] * size),
			general: {
				base: {
					label: {
						mobile: parseFontSize(1.4 * size),
						desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
					},
					input: {
						mobile: parseFontSize(1.4 * size),
						desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
					},
					confirmationInfo: {
						mobile: parseFontSize(1.4 * size),
						desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
					},
					button: {
						mobile: parseFontSize(1.6 * size),
						desktop: parseFontSize(1.8 * size)
					}
				},
				password: {
					label: {
						mobile: parseFontSize(1.4 * size),
						desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
					}
				},
				accountRemoval: {
					label: {
						mobile: parseFontSize(1.4 * size),
						desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
					}
				}
			},
			parentalControl: {
				status: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(1.8 * size)
				}
			},
			table: {
				header: parseFontSize(1.4 * size),
				cell: parseFontSize(1.4 * size)
			},
			agreements: {
				description: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
				},
				obligatoryPrefix: parseFontSize(1.4 * size),
				button: {
					mobile: parseFontSize(FONT_SIZE_TYPES[xs] * size),
					desktop: parseFontSize(1.8 * size)
				}
			}
		},
		slider: {
			title: {
				mobile: parseFontSize(2.2 * size),
				desktop: parseFontSize(3.4 * size)
			},
			showAllButton: {
				mobile: parseFontSize(1.2 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			item: {
				title: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[md] * size)
				},
				description: {
					mobile: parseFontSize(0.8 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
				}
			}
		},
		search: {
			input: {
				mobile: parseFontSize(1.6 * size),
				desktop: parseFontSize(1.8 * size)
			},
			searchBox: {
				showAllLink: parseFontSize(1.8 * size)
			}
		},
		sidebar: {
			title: parseFontSize(1.8 * size),
			productTitle: parseFontSize(FONT_SIZE_TYPES[xs] * size),
			productType: parseFontSize(1.4 * size)
		},
		contact: {
			text: parseFontSize(1.8 * size)
		},
		player: {
			box: {
				text: parseFontSize(1.8 * size),
				button: parseFontSize(1.1 * size)
			},
			parental: {
				button: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			error: parseFontSize(2 * size)
		},
		epg: {
			calendarText: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(1.8 * size)
			},
			catchup: parseFontSize(1 * size),
			gridItemTitle: parseFontSize(FONT_SIZE_TYPES[xs] * size),
			gridItemDescription: parseFontSize(1.4 * size),
			time: parseFontSize(1.2 * size)
		},
		popover: {
			placeholder: parseFontSize(1.4 * size),
			time: parseFontSize(1.4 * size),
			title: parseFontSize(FONT_SIZE_TYPES[xs] * size),
			genres: parseFontSize(1.4 * size),
			description: parseFontSize(1.2 * size),
			link: parseFontSize(1.2 * size)
		},
		programDetails: {
			title: {
				mobile: parseFontSize(2.6 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xl] * size)
			},
			infoItemText: {
				mobile: parseFontSize(1.2 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			description: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(2.2 * size)
			},
			persons: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			}
		},
		payment: {
			heading: {
				mobile: parseFontSize(2.2 * size),
				desktop: parseFontSize(3.8 * size)
			},
			paymentInfo: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			agreements: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			payInfo: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			},
			paymentOptions: {
				name: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[md] * size)
				},
				price: {
					mobile: parseFontSize(1.4 * size),
					desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
				}
			},
			agreementsInfo: parseFontSize(1.4 * size),
			input: parseFontSize(1.4 * size)
		},
		radio: {
			mobile: parseFontSize(1.4 * size),
			desktop: parseFontSize(1.8 * size)
		},
		remindersMenu: parseFontSize(1.4 * size),
		authentication: {
			heading: {
				mobile: parseFontSize(1.7 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[md] * size)
			},
			agreements: {
				modal: {
					heading: parseFontSize(1.6 * size)
				}
			}
		},
		share: {
			mobile: parseFontSize(1.4 * size),
			desktop: parseFontSize(FONT_SIZE_TYPES[md] * size)
		},
		// Products on preview of all section items and preview of all search results
		productItemsGrid: {
			tileTitle: {
				mobile: parseFontSize(1.4 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[md] * size)
			},
			tileDescription: {
				mobile: parseFontSize(0.8 * size),
				desktop: parseFontSize(FONT_SIZE_TYPES[xs] * size)
			}
		},
		commonButton: parseFontSize(FONT_SIZE_TYPES[xs] * size),
		portalUnavailable: parseFontSize(2.5 * size)
	}
});
