import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import services from 'services/services';
import { fetchActiveSubscriptionsList } from 'store/actions';

// Import helpers
import { getErrorCodeResults } from 'helpers/error_code_api';
import { PAYMENT_METHODS } from 'helpers/prices';
import { openNotification } from 'components/utilities/notification/notification';

// Import api
import { getSubscriptionCancelApi } from 'helpers/api';

const useSubscriptionManage = (uuid) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const subscriptions = useSelector(
		({ activeSubscriptions }) => activeSubscriptions.data
	);

	const subscription = subscriptions.find(
		({ id, product_uuid }) => id === uuid || product_uuid === uuid
	);

	const id = subscription?.id;
	const status = subscription?.status;
	const provider = subscription?.provider;
	const isBluemedia = provider === PAYMENT_METHODS.BLUEMEDIA;

	const { isLoading, mutateAsync: subscriptionCancel } = useMutation({
		mutationFn: () => {
			const url = getSubscriptionCancelApi({ provider, id });
			return services.post(url);
		},
		onSuccess: () => fetchActiveSubscriptionsList()(dispatch),
		onError: (error) => {
			const { codeErrorMessage } = getErrorCodeResults(error);

			openNotification({
				type: 'error',
				title: t('vod_root_error_title'),
				description: codeErrorMessage
			});
		}
	});

	return {
		isLoading,
		status,
		isBluemedia,
		provider,
		subscriptionCancel
	};
};

export default useSubscriptionManage;
