import { Drawer } from 'antd';
import styled from 'styled-components';

// Import styles
import { flex } from 'components/styles';

export const DrawerStyled = styled(Drawer)`
	&& {
		.ant-drawer-wrapper-body,
		.ant-drawer-content {
			background: ${({ theme: { sidebar } }) => sidebar.bg};
		}

		.ant-drawer-header {
			background: transparent;
			border-bottom-color: ${({ theme }) => theme.line};
			padding: 2.8rem 2.6rem 2.8rem 2.8rem;
			${flex({ jc: 'space-between' })}
		}

		.ant-drawer-title {
			font-size: ${({ theme }) => theme.fontSize.sidebar.title}rem;
			line-height: 2.7rem;
			font-weight: ${({ theme }) => theme.font.semiBold};
			color: ${({ theme }) => theme.white};
		}

		.ant-drawer-close {
			color: ${({ theme: { sidebar } }) => sidebar.closeIcon};
			position: relative;
			width: 2.2rem;
			height: 2.2rem;
			line-height: 2.2rem;
			font-size: 2.2rem;

			&:hover {
				color: ${({ theme: { sidebar } }) => sidebar.closeIconHover};
			}

			&:focus-visible {
				color: ${({ theme: { sidebar } }) => sidebar.closeIconHover};
			}
		}

		.ant-drawer-body {
			padding: 0 0 2.8rem 0;
		}
	}
`;
