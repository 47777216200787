import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Import styles
import {
	HrLine,
	ShareSection,
	ShareText,
	StyledFacebookShareButton,
	IconFacebook
} from './styles';

// Import helpers
import { checkProductSubtype } from 'helpers';

const { REACT_APP_WEBSITE_URL } = process.env;

const Share = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const subtype = useSelector(({ movieDetails }) => movieDetails.data?.subtype);

	const { isArticle } = checkProductSubtype(subtype);

	const shareUrl = `${REACT_APP_WEBSITE_URL}${pathname}`;

	const lightBackground = `${isArticle}`;

	return (
		<>
			<HrLine isLightBackground={isArticle} />
			<ShareSection>
				<ShareText isLightBackground={isArticle}>
					{t('common_share')}:{' '}
				</ShareText>
				<StyledFacebookShareButton light={lightBackground} url={shareUrl}>
					<IconFacebook aria-hidden="true" />
				</StyledFacebookShareButton>
			</ShareSection>
		</>
	);
};

export default Share;
