import styled, { css } from 'styled-components';
import { MenuButton } from '@reach/menu-button';

// Import styles
import {
	flex,
	transitionMain,
	customMinResponsive,
	size,
	customResponsive
} from 'components/styles';

// Import icons
import { ReactComponent as VodHeartIcon } from 'assets/icons/vod_heart.svg';
import { ReactComponent as VodHeartFullIcon } from 'assets/icons/vod_heart_full.svg';
import { ReactComponent as IconReminderSVG } from 'assets/icons/ic_reminder.svg';
import { ReactComponent as IconPlay } from 'assets/icons/vod_play.svg';

const iconsCommonStyles = css`
	margin-right: 8px;
	height: 100%;
`;

export const buttonsCommonStyles = css`
	font-size: ${({ theme }) => theme.fontSize.commonButton}rem;
	color: ${({ theme }) => theme.product.buttons.secondaryColor};
	background-color: ${({ theme }) => theme.product.buttons.secondaryBg};
	height: 5rem;
	border: 2px solid ${({ theme }) => theme.product.buttons.secondaryBorder};
	border-radius: ${({ theme }) => theme.product.buttons.roundSize};
	cursor: pointer;
	font-weight: ${({ theme }) => theme.font.bold};
	margin-bottom: 1.4rem;
	padding: 0 0.5rem;
	position: relative;
	${flex()}
	${transitionMain}

	&:disabled {
		cursor: not-allowed;
		color: ${({ theme }) => theme.black};
	}

	&:hover {
		color: ${({ theme }) => theme.product.buttons.secondaryColorHover};
		border-color: ${({ theme }) => theme.product.buttons.secondaryBorderHover};
		background-color: ${({ theme }) => theme.product.buttons.secondaryBgHover};
	}

	&:focus-visible {
		color: ${({ theme }) => theme.product.buttons.secondaryColorHover};
		border-color: ${({ theme }) => theme.product.buttons.secondaryBorderHover};
		background-color: ${({ theme }) => theme.product.buttons.secondaryBgHover};
	}

	${({ prime }) =>
		prime === 1 &&
		css`
			color: ${({ theme }) => theme.product.buttons.primaryColor}
			background-color: ${({ theme }) => theme.product.buttons.primaryBg};
			border-color: ${({ theme }) => theme.product.buttons.primaryBorder};

			&:hover {
				color: ${({ theme }) => theme.product.buttons.primaryColorHover};
				background-color: ${({ theme }) => theme.product.buttons.primaryBgHover};
				border-color: ${({ theme }) => theme.product.buttons.primaryBorderHover};
			};

			&:focus-visible {
				color: ${({ theme }) => theme.product.buttons.primaryColorHover};
				background-color: ${({ theme }) => theme.product.buttons.primaryBgHover};
				border-color: ${({ theme }) => theme.product.buttons.primaryBorderHover};
			};
		`}

	${customResponsive(
		size.small,
		css`
			width: 100%;
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			padding: 0 4rem;

			${({ prime }) =>
				prime === 1 &&
				css`
					padding: 0 4.6rem;
				`};
		`
	)}
`;

export const VerticalBar = styled.span`
	margin: 0 0.7rem;
`;

export const StyledButton = styled.button`
	${buttonsCommonStyles}
`;

export const StyledMenuButton = styled(MenuButton)`
	${customMinResponsive(
		size.small,
		css`
			padding: 0 2rem;
		`
	)}

	${customMinResponsive(
		size.medium,
		css`
			padding: inherit;
		`
	)}

	${buttonsCommonStyles}
`;

export const IconFavorite = styled(VodHeartIcon)`
	${iconsCommonStyles}
`;

export const IconFavoriteFull = styled(VodHeartFullIcon)`
	${iconsCommonStyles}
`;

export const IconReminder = styled(IconReminderSVG)`
	${iconsCommonStyles}
`;

export const PlayIcon = styled(IconPlay)`
	${iconsCommonStyles}
`;
