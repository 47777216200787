import styled, { css } from 'styled-components';

// Import styles
import { customResponsive, flex, size } from 'components/styles';

export const Wrapper = styled.div`
	position: relative;
	${flex()}
	column-gap: 2rem;
	width: auto;

	${customResponsive(
		size.medium,
		css`
			width: 100%;
			column-gap: 1rem;
			svg {
				width: 3.3rem;
			}
		`
	)}
`;
