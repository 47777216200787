import { useSelector } from 'react-redux';

// Import helpers
import { createLinkParams } from 'helpers/index';
import {
	getProductTypeAlias,
	getCurrentChannelProgramUuid
} from 'helpers/products';

// Import hooks
import useProductRatingCheck from 'components/elements/molecules/parental_control/parental_control_utils/useProductRatingCheck';
import { useSearchInstitutions } from '../useSearchInstitutions';

// Import variables
import { PRODUCTS_TYPES } from 'helpers/variables';

const { LIVE_EVENT, CHANNEL } = PRODUCTS_TYPES;

export const useSearchItem = ({ product, close }) => {
	const { clearInstitution } = useSearchInstitutions(close);
	const channels = useSelector(({ channels }) => channels.data.lives);

	// REPLACE RATING WITH PC_RATING AFTER API UPDATE
	const { uuid, title, type, subtype, context, pc_rating } = product;

	const since = context?.since;

	// check program rating and block content accordingly
	const { isContentBlocked, placeholderRatingText } = useProductRatingCheck(
		pc_rating,
		since
	);

	const isLive = type === LIVE_EVENT;
	const isChannel = type === CHANNEL;

	const hash = isLive ? 'live' : 'program';

	// Set proper id to channel type product
	const id = !isChannel
		? uuid
		: channels &&
		  getCurrentChannelProgramUuid({
				channels,
				currentChannelUuid: uuid
		  });

	const linkParams = createLinkParams({
		id,
		type: subtype,
		title,
		hash
	});

	const productTypeAlias = getProductTypeAlias(product);

	return {
		linkParams,
		productTypeAlias,
		isContentBlocked,
		placeholderRatingText,
		title,
		onCloseSearchBox: clearInstitution
	};
};
