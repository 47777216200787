import React from 'react';
import { number, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

// Import actions
import { addReminder } from 'store/actions';

// import helpers
import { PRODUCTS_TYPES } from 'helpers/variables';
import { getProductType } from 'helpers/products';
import { DATE_FORMAT } from 'helpers/variables';

// Import styles
import { OptionItem } from './styles';

const { PROGRAM, LIVE_EVENT } = PRODUCTS_TYPES;
const { LOCALE_DATE_FORMAT } = DATE_FORMAT;

const OptionsItem = ({ minutes, timeDifference, startDate }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { pathname } = useLocation();

	const formattedStartDate = moment(startDate)?.format(LOCALE_DATE_FORMAT);

	// check path to switch between live and program
	const productType =
		getProductType(pathname) === 'live' ? LIVE_EVENT : PROGRAM;

	if (timeDifference < minutes) return null;

	const handleOffsetSelect = () => {
		const data = {
			productUuid: id,
			productType,
			offset: minutes,
			startDate: formattedStartDate || ''
		};
		addReminder(data)(dispatch);
	};

	return (
		<OptionItem onSelect={handleOffsetSelect}>
			{t(`common_reminders_list_${minutes}_before`)}
		</OptionItem>
	);
};

OptionsItem.propTypes = {
	minutes: number.isRequired,
	timeDifference: number.isRequired,
	startDate: string.isRequired
};

export default OptionsItem;
