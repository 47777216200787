import React from 'react';

// Import variables
import { LOCAL_STORAGE_LANGUAGE, LANGUAGES_TYPES } from 'helpers/variables';

// Import helpers
import { getUserLanguage } from 'helpers/languages';

const { EN } = LANGUAGES_TYPES;

const useLanguage = () => {
	const [language, setLanguage] = React.useState(getUserLanguage());

	const updateLanguage = (value) => () => {
		// English is temporarily not available for users
		if (value === EN) return;

		setLanguage(value);
		// set new language value in local storage
		localStorage.setItem(LOCAL_STORAGE_LANGUAGE, value);
		// force reload
		window.location.reload();
	};

	return { language, updateLanguage };
};

export default useLanguage;
