// Import variables
import { LOCAL_STORAGE_LANGUAGE, LANGUAGES_TYPES } from 'helpers/variables';

export const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

export const projectlanguages = process.env.REACT_APP_LANGUAGES.split(',');

export const languagesOptions = projectlanguages.map((lang) => ({
	name: lang,
	value: lang
}));

export const getUserLanguage = () => {
	const localStorageLang = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
	const defualtLang =
		process.env.REACT_APP_DEFAULT_LANGUAGE || LANGUAGES_TYPES.EN;
	return localStorageLang ?? defualtLang;
};

export const hasSeveralLanguages = projectlanguages.length > 1;
