import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setPlayerCustomError } from 'store/actions';

// Import utilities
import { useFullScreen } from 'components/utilities';

// Import helpers
import { getUserAgentInfo } from 'helpers/index';

// Import components
import PlayerTvContent from '../player_content/PlayerTvContent';
import PlayerProvider from './PlayerProvider';
import { FloatWidget } from 'components/elements';

const PlayerContext = ({
	isSiedebarVisible,
	type,
	isFocus,
	error,
	setFullScreen,
	isCatchup,
	resetFocus,
	playerParentElement,
	setPlayerCustomError
}) => {
	const { isSafari, isIphone } = getUserAgentInfo();

	const { isFullScreen } = useFullScreen({ isIphone });

	React.useEffect(() => {
		// Set fullScreen and sent prop to parent component to change style on fullscreen
		setFullScreen(isFullScreen);
		/*eslint-disable-next-line  */
	}, [isFullScreen]);

	return (
		<PlayerProvider
			isCatchup={isCatchup}
			isSiedebarVisible={isSiedebarVisible}
			type={type}
			isSafari={isSafari}
			isFocus={isFocus}
			resetFocus={resetFocus}
			playerParentElement={playerParentElement}
		>
			<FloatWidget>
				<PlayerTvContent
					error={error}
					setPlayerCustomError={setPlayerCustomError}
					isCatchup={isCatchup}
					isFullScreen={isFullScreen}
				/>
			</FloatWidget>
		</PlayerProvider>
	);
};

PlayerContext.propTypes = {
	error: PropTypes.shape({ isError: PropTypes.bool.isRequired }).isRequired,
	type: PropTypes.string.isRequired,
	isFocus: PropTypes.bool.isRequired,
	isSiedebarVisible: PropTypes.bool.isRequired,
	setFullScreen: PropTypes.func.isRequired,
	isCatchup: PropTypes.bool.isRequired,
	resetFocus: PropTypes.func.isRequired,
	playerParentElement: PropTypes.any.isRequired,
	setPlayerCustomError: PropTypes.func.isRequired
};

const mapStateToProps = (state, { type }) => {
	const { error } = state[type];
	return {
		error
	};
};

export default connect(mapStateToProps, { setPlayerCustomError })(
	PlayerContext
);
