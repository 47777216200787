import React from 'react';
import PropTypes from 'prop-types';

// Import logic
import useNavItem from './useNavItem';

// Import components
import * as Nav from '../styles';

const NavItem = ({ slug, name, path, modifiers }) => {
	const { url } = useNavItem({ path, slug });

	return (
		<Nav.Item modifiers={modifiers}>
			<Nav.NavLink
				exact
				to={url}
				activeClassName="active-nav"
				modifiers={modifiers}
			>
				{name}
			</Nav.NavLink>
		</Nav.Item>
	);
};

NavItem.propTypes = {
	slug: PropTypes.string,
	name: PropTypes.string,
	path: PropTypes.string,
	modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default NavItem;
