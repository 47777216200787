import styled, { css } from 'styled-components';
import { customMinResponsive, size } from 'components/styles';

export const List = styled.ul`
	margin: 2.6rem 0 1rem 0;
	display: flex;
	list-style: none;

	${customMinResponsive(
		size.medium,
		css`
			margin: 0 6rem 0 0;
		`
	)};
`;

export const Item = styled.li`
	padding: 0 1.45rem;
	height: 2.1rem;
	position: relative;
	cursor: pointer;

	&::after {
		position: absolute;
		top: 0;
		right: 0;
		content: '';
		display: block;
		height: 100%;
		width: 1.5px;
		background-color: ${({ theme }) => theme.white};
		opacity: 0.4;
	}

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;

		&:after {
			display: none;
		}
	}
`;

export const Language = styled.button`
	cursor: pointer;
	border: none;
	outline: none;
	background: none;
	font-weight: ${({ theme }) => theme.font.medium};
	font-size: ${({ theme }) => theme.fontSize.footer.media.link}rem;

	&:hover,
	&:focus-visible {
		color: ${({ theme }) => theme.body.footer.hover};
	}

	${({ isSelected }) =>
		isSelected &&
		css`
			color: ${({ theme }) => theme.white};
			font-weight: ${({ theme }) => theme.font.medium};
		`}
`;
