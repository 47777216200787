import React from 'react';
import { useTranslation } from 'react-i18next';

// Import images
import { ReactComponent as AppStore } from 'assets/icons/ic_app_store.svg';
import { ReactComponent as GooglePlay } from 'assets/icons/ic_google_play.svg';

// Import styled components
import { Stores, AnchorStyled } from './styles';

// Import variables
import { EXTERNAL_LINKS } from 'helpers/variables';

const { APP_STORE, GOOGLE_PLAY } = EXTERNAL_LINKS();

const FooterStores = () => {
	const { t } = useTranslation();

	return (
		<Stores>
			<AnchorStyled
				target="_blank"
				rel="noopener noreferrer"
				href={APP_STORE}
				aria-label={t('aria_footer_app_store')}
				title={t('aria_footer_app_store')}
			>
				<AppStore aria-hidden="true" />
			</AnchorStyled>
			<AnchorStyled
				target="_blank"
				rel="noopener noreferrer"
				href={GOOGLE_PLAY}
				aria-label={t('aria_footer_google_play')}
				title={t('aria_footer_google_play')}
			>
				<GooglePlay aria-hidden="true" />
			</AnchorStyled>
		</Stores>
	);
};

export default FooterStores;
