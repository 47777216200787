import React from 'react';
import { useTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as BlueMediaIcon } from 'assets/icons/bluemedia.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/visa.svg';
import { ReactComponent as MasterIcon } from 'assets/icons/mc.svg';
import { ReactComponent as BlikIcon } from 'assets/icons/blik.svg';
import { ReactComponent as DcbIcon } from 'assets/icons/dcb.svg';

// Import styles
import { Wrapper } from './styles';

const PaymentInfo = () => {
	const { t } = useTranslation();
	return (
		<Wrapper>
			<BlueMediaIcon
				title={t('common_payments_bluemedia')}
				aria-label={t('common_payments_bluemedia')}
			/>
			<VisaIcon
				title={t('common_payments_visa_card')}
				aria-label={t('common_payments_visa_card')}
			/>
			<MasterIcon
				title={t('common_payments_master_card')}
				aria-label={t('common_payments_master_card')}
			/>
			<BlikIcon
				title={t('common_payments_blik')}
				aria-label={t('common_payments_blik')}
			/>
			<DcbIcon
				title={t('common_payments_dcb')}
				aria-label={t('common_payments_dcb')}
			/>
		</Wrapper>
	);
};

export default PaymentInfo;
