// Import variables
import { PAYMENT_METHODS } from 'helpers/prices';

const { DCB_SUBSCRIPTION, BLUEMEDIA } = PAYMENT_METHODS;

// API
export const GET_LAST_GATEWAY_API = '/payments/getLastGatewayType';
export const GET_PAYMENT_AGREEMENTS_API = '/payments/agreements';
export const GET_VOUCHER_PROMOTION_API = '/payments/id/code/promotion/prices';
export const GET_PRODUCT_PAYMENT_STATUS_API = '/payments/payment/last/:id';

export const POST_PAYMENT_INIT_API = '/payments/productId/priceId/init';
export const POST_PAYMENT_BUY_API = '/payments/productId/priceId/buy';

// subscriptions
export const POST_SUBSCRIPTION_DEACTIVATE_API =
	'/payments/subscriptions/deactivate';

export const POST_EXTERNAL_SUBSCRIPTION_DEACTIVATE_API = `/payments/:type/subscription/:id/cancel`;

// Queries
export const QUERY_PAYMENT_RESOURCES = 'QUERY_PAYMENT_RESOURCES';
export const QUERY_VOUCHER_PROMOTION = 'QUERY_VOUCHER_PROMOTION';
export const QUERY_PAYMENT_INIT_ = 'QUERY_PAYMENT_INIT';
export const QUERY_PAYMENT_BUY = 'QUERY_PAYMENT_BUY';
export const QUERY_PRODUCT_PAYMENT_STATUS = 'QUERY_PRODUCT_PAYMENT_STATUS';

export const getPaymentInitApi = ({ type, productId, priceId }) => {
	switch (type) {
		case DCB_SUBSCRIPTION:
			return `/payments/dcb/subscription/${productId}/${priceId}/init`;

		default:
			return `/payments/${productId}/${priceId}/init`;
	}
};

export const getProductPurchaseApi = ({
	paymentMethod,
	productId,
	priceId
}) => {
	switch (paymentMethod) {
		case DCB_SUBSCRIPTION:
			return '/payments/dcb/subscription/subscribe';

		default:
			return `/payments/${productId}/${priceId}/buy`;
	}
};

export const getSubscriptionCancelApi = ({ provider, id }) => {
	switch (provider) {
		case BLUEMEDIA:
			return `/payments/subscriptions/deactivate/${id}`;

		default:
			return `/payments/${provider}/subscription/${id}/cancel`;
	}
};
