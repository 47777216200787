import styled, { css } from 'styled-components';

//Import styles
import {
	customMinResponsive,
	size,
	textEllipsis,
	customResponsive
} from 'components/styles';

// Import variables
import { DESCRIPTION_LINE_HEIGHT_LIMIT } from 'helpers/variables';

const { LINE_HEIGHT_DESKTOP, LINE_HEIGHT_MOBILE } =
	DESCRIPTION_LINE_HEIGHT_LIMIT;

export const Wrapper = styled.div`
	width: 100%;
	max-width: 100%;
	position: relative;
	z-index: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 2rem;
	padding-right: 2rem;
	${customMinResponsive(
		size.medium,
		css`
			padding-right: 0;
			margin-bottom: 9.4rem;
			max-width: 80rem;
		`
	)}
`;

export const Title = styled.h2`
	font-size: ${({ theme }) => theme.fontSize.product.media.title}rem;
	line-height: 2.5rem;
	margin-bottom: 2.2rem;
	color: ${({ theme }) => theme.white};
	${textEllipsis}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.xl}rem;
			line-height: 5.7rem;
			margin-bottom: 2.5rem;
		`
	)}
`;

export const Description = styled.p`
	line-height: ${LINE_HEIGHT_MOBILE}px;
	height: ${LINE_HEIGHT_MOBILE * 5}px;
	max-height: ${LINE_HEIGHT_MOBILE * 5}px;
	font-size: ${({ theme }) => theme.fontSize.product.media.description}rem;
	margin-bottom: 3.4rem;
	color: ${({ theme }) => theme.white};
	overflow: hidden;
	transition: height ease 300ms;
	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) => theme.fontSize.md}rem;
			margin-bottom: 3.6rem;
			line-height: ${LINE_HEIGHT_DESKTOP}px;
			height: ${LINE_HEIGHT_DESKTOP * 5}px;
			max-height: ${LINE_HEIGHT_DESKTOP * 5}px;
		`
	)}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	${customResponsive(
		size.small,
		css`
			padding-bottom: ${({ isPreview }) => (!isPreview ? '5.6rem' : 'unset')};
		`
	)}

	${customMinResponsive(
		size.small,
		css`
			> * {
				margin-right: 1.4rem;
			}
		`
	)}
`;

export const StyledTooltip = styled.div`
	> .ant-tooltip-inner {
		color: ${({ theme }) => theme.white};
		background-color: transparent;
		font-size: 2.4rem !important;
	}
`;
