import i18n from 'i18next';

// Import translations
import * as errors from 'translations/errors';

export const getCodeErrorMessage = (code) => {
	const { language } = i18n;

	const currentLanguage = errors[language];

	if (currentLanguage && currentLanguage[code]) {
		return currentLanguage[code];
	}

	return '';
};

export const ERROR_CODE_API_TYPES = {
	RESOURCE_NOT_FOUND: 'RESOURCE_NOT_FOUND',
	RESOURCE_NOT_ACTIVE: 'RESOURCE_NOT_ACTIVE',
	VIDEO_NOT_FOUND: 'VIDEO_NOT_FOUND',
	PROGRAM_NOT_FOUND: 'PROGRAM_NOT_FOUND',
	RESOURCE_NOT_AVAILABLE: 'RESOURCE_NOT_AVAILABLE',
	SUBSCRIBER_PARALLEL_STREAMS_LIMIT_EXCEEDED:
		'SUBSCRIBER_PARALLEL_STREAMS_LIMIT_EXCEEDED',
	SUBSCRIBER_GROUP_STREAMS_LIMIT_EXCEEDED:
		'SUBSCRIBER_GROUP_STREAMS_LIMIT_EXCEEDED',
	PROGRAM_BLACKOUT: 'PROGRAM_BLACKOUT',
	STARTOVER_NOT_ACTIVE_FOR_CHANNEL: 'STARTOVER_NOT_ACTIVE_FOR_CHANNEL',
	CATCHUP_NOT_ACTIVE_FOR_CHANNEL: 'CATCHUP_NOT_ACTIVE_FOR_CHANNEL',
	CATCHUP_NOT_AVAILABLE_FOR_PROGRAM: 'CATCHUP_NOT_AVAILABLE_FOR_PROGRAM',
	NPVR_NOT_ACTIVE_FOR_CHANNEL: 'NPVR_NOT_ACTIVE_FOR_CHANNEL',
	NPVR_NOT_AVAILABLE_FOR_PROGRAM: 'NPVR_NOT_AVAILABLE_FOR_PROGRAM',
	BLOCKED_BY_PARENTAL_CONTROL: 'BLOCKED_BY_PARENTAL_CONTROL',
	NOT_AVAILABLE_ON_PLATFORM: 'NOT_AVAILABLE_ON_PLATFORM',
	MUST_BE_IN_LOCAL: 'MUST_BE_IN_LOCAL',
	RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS: 'RESOURCE_NOT_IN_SUBSCRIBER_PRODUCTS',
	IP_BLACKLISTED: 'IP_BLACKLISTED',
	STB_CONNECTION_REFUSED: 'STB_CONNECTION_REFUSED',
	VIDEO_SESSION_INVALID: 'VIDEO_SESSION_INVALID',
	LIST_RECORDING_FAILURE_EXCEPTION: 'LIST_RECORDING_FAILURE_EXCEPTION',
	ADD_RECORDING_FAILURE_EXCEPTION: 'ADD_RECORDING_FAILURE_EXCEPTION',
	DELETE_RECORDING_EXCEPTION: 'DELETE_RECORDING_EXCEPTION',
	ACCOUNT_DETAILS_EXCEPTION: 'ACCOUNT_DETAILS_EXCEPTION',
	INVALID_API_DEVICE_HEADER: 'INVALID_API_DEVICE_HEADER',
	INVALID_PIN: 'INVALID_PIN',
	PIN_WRONG_OLD_PIN: 'PIN_WRONG_OLD_PIN',
	PIN_CANNOT_BE_EMPTY: 'PIN_CANNOT_BE_EMPTY',
	INVALID_PLATFORM: 'INVALID_PLATFORM',
	PLATFORM_MISSING: 'PLATFORM_MISSING',
	INVALID_UUID: 'INVALID_UUID',
	CANNOT_BUY_PRODUCT: 'CANNOT_BUY_PRODUCT',
	INVALID_PAYMENT_PIN: 'INVALID_PAYMENT_PIN',
	INVALID_PRICE_ENTRY: 'INVALID_PRICE_ENTRY',
	NOT_ENOUGH_CREDITS: 'NOT_ENOUGH_CREDITS',
	PRICE_ENTRY_OUT_OF_DATE: 'PRICE_ENTRY_OUT_OF_DATE',
	SGT_AUTHENTICATION_FAILED: 'SGT_AUTHENTICATION_FAILED',
	MISSING_EMAIL: 'MISSING_EMAIL',
	ESALES_SERVICE_UNAVAILABLE: 'ESALES_SERVICE_UNAVAILABLE',

	// DEVICES
	ADD_RECORDING_CONFLICTS: 'ADD_RECORDING_CONFLICTS',
	ADD_RECORDING_DISK_FULL: 'ADD_RECORDING_DISK_FULL',
	ADD_RECORDING_UNKNOWN_ERROR: 'ADD_RECORDING_UNKNOWN_ERROR',
	ACCOUNT_DETAILS_NOT_FOUND: 'ACCOUNT_DETAILS_NOT_FOUND',
	ACCOUNT_DETAILS_UNKNOWN_ERROR: 'ACCOUNT_DETAILS_UNKNOWN_ERROR',
	DELETE_RECORDING_NOT_FOUND: 'DELETE_RECORDING_NOT_FOUND',
	DELETE_RECORDING_WRONG_PIN: 'DELETE_RECORDING_WRONG_PIN',
	DELETE_RECORDING_UNKNOWN_ERROR: 'DELETE_RECORDING_UNKNOWN_ERROR',

	// LOGIN AUTH
	AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
	BEARER_TOKEN_REQUIRED: 'BEARER_TOKEN_REQUIRED',
	AUTHENTICATION_REQUIRED: 'AUTHENTICATION_REQUIRED',
	DEVICES_LIMIT_EXCEEDED: 'DEVICES_LIMIT_EXCEEDED',
	DEVICE_NOT_EXISTS: 'DEVICE_NOT_EXISTS',
	SUBSCRIBER_DEVICES_CHANGING_LIMIT_EXCEEDED:
		'SUBSCRIBER_DEVICES_CHANGING_LIMIT_EXCEEDED',

	// PROMOTIONS
	CANNOT_USE_PROMOTION: 'CANNOT_USE_PROMOTION',
	INVALID_PROMOTION_CODE: 'INVALID_PROMOTION_CODE',

	// REGISTRATION
	RESOURCE_ALREADY_EXISTS: 'RESOURCE_ALREADY_EXISTS',
	PUREOTT_SUBSCRIBER_ACCOUNT_SUSPENDED: 'PUREOTT_SUBSCRIBER_ACCOUNT_SUSPENDED',
	PUREOTT_SUBSCRIBER_WRONG_CREDENTIALS: 'PUREOTT_SUBSCRIBER_WRONG_CREDENTIALS',
	PUREOTT_SUBSCRIBER_EMAIL_APPROVE_EXPIRED:
		'PUREOTT_SUBSCRIBER_EMAIL_APPROVE_EXPIRED',
	PUREOTT_SUBSCRIBER_NOT_FOUND: 'PUREOTT_SUBSCRIBER_NOT_FOUND',

	// LOGIN WITH CODE
	SUBSCRIBER_LOGIN_CODE_EXPIRED: 'SUBSCRIBER_LOGIN_CODE_EXPIRED',
	DEVICE_LIMIT_EXCEEDED_EXCEPTION: 'DEVICE_LIMIT_EXCEEDED_EXCEPTION',
	DEVICE_CHANGES_LIMIT_EXCEEDED_EXCEPTION:
		'DEVICE_CHANGES_LIMIT_EXCEEDED_EXCEPTION'
};

export const DEFAULT_ERROR_CODE =
	'Wystąpił problem z komunikacją. Prosimy spróbować ponownie za chwilę.';

export const ERROR_CODE_API_STATUS = {
	500: 'Wystąpił problem z komunikacją z serwerem.',
	502: 'Portal chwilowo niedostepny.'
};

export const getErrorCodeResults = (error) => {
	const errorData = error?.response?.data;

	if (errorData) {
		const { message, errorCode, status } = errorData;

		const codeErrorMessage =
			getCodeErrorMessage(errorCode) ||
			ERROR_CODE_API_STATUS[status] ||
			message;

		return { codeErrorMessage, errorCode };
	}

	return { codeErrorMessage: '', errorCode: '' };
};

export const getErrorStatus = (status) => {
	switch (status) {
		case 502:
			return ERROR_CODE_API_STATUS[502];

		case 500:
			return ERROR_CODE_API_STATUS[500];

		default:
			return DEFAULT_ERROR_CODE;
	}
};

export const CUSTOM_ERROR = {
	//Mock error for player parental control
	CUSTOM_PARENTAL_CONTROL_ERROR: {
		response: {
			data: {
				isError: true,
				code: 400,
				message:
					'Wybrany materiał jest niedostępny ze względu na włączoną kontrolę rodzicielską.',
				errorCode: 'BLOCKED_BY_PARENTAL_CONTROL'
			}
		}
	},
	//Mock error for player end of program in program details
	CUSTOM_END_OF_PROGRAM: {
		response: {
			data: {
				isError: true,
				code: 400,
				message: 'Koniec wybranego programu',
				errorCode: 'END_OF_PROGRAM'
			}
		}
	},
	CUSTOM_REQUEST_INVALID_DATA: 'Niepoprawne dane'
};

// HTTP response status codes
export const HTTP_STATUS_CODES = {
	NOT_ACCEPTABLE: 406
};
