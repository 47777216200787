import React from 'react';

// Import styled components
import { Wrapper, MenusContainer, Container } from './styles';

// Import components
import Hamburger from '../hamburger';
import NavSearchForm from '../nav_search/NavSerachForm';
import MobileMenu from './MobileMenu';
import NavTemplate from '../nav_template';
import PortalLogo from '../portal_logo';
import SearchButton from '../nav_toolbar/SearchButton';

// Import logic
import { useMobileNav } from './useMobileNav';

const MobileNav = () => {
	// block body scrolling if mobile menu is open
	const { isMenuOpen, isSearchingOpen, toggleMenu, toggleSearching } =
		useMobileNav();

	return (
		<>
			<NavTemplate>
				<Wrapper>
					{isSearchingOpen ? (
						<NavSearchForm
							toggle={toggleSearching}
							isMobileView
							autoFocus={true}
						/>
					) : (
						<>
							<PortalLogo />
							<Container>
								<SearchButton toggle={toggleSearching} />
								<Hamburger isOpen={isMenuOpen} toggle={toggleMenu} />
							</Container>
						</>
					)}
				</Wrapper>
			</NavTemplate>
			{isMenuOpen && (
				<MenusContainer>
					<MobileMenu toggle={toggleMenu} />
				</MenusContainer>
			)}
		</>
	);
};

export default MobileNav;
