import React from 'react';
import { func, number } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Button } from 'components/elements';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import helpers
import { getTimeLineFormat } from 'helpers';

const Resume = React.memo(
	({ currentTime, toggle }) => {
		const { t } = useTranslation();
		const { play, pause, restart, updateTime } = usePlayerVodContext();

		React.useEffect(() => {
			// Pause player
			pause();
			// eslint-disable-next-line
		}, []);

		const handleContinueWatching = (toggle) => () => {
			// Seek to current time
			updateTime(currentTime);
			// Play player
			play();
			// Close
			toggle();
		};

		const handleRestartWatching = (toggle) => () => {
			// Restart
			restart();
			// Pause player
			play();
			// Close
			toggle();
		};

		return (
			<Wrapper classNAme="RESUME">
				<Box>
					<Text>
						{t('player_remuse_desc_part_1')}
						<br />
						{t('player_remuse_desc_part_2')}{' '}
						{getTimeLineFormat(currentTime - 2)}?
					</Text>
					<Controllers>
						<Button
							onClick={handleRestartWatching(toggle)}
							type="button"
							modifiers="primaryVod"
							cancel
						>
							{t('player_remuse_btn_restart')}
						</Button>
						<Button
							onClick={handleContinueWatching(toggle)}
							type="button"
							modifiers="primaryVod"
						>
							{t('player_remuse_btn_remuse')}
						</Button>
					</Controllers>
				</Box>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	border: 0;
	z-index: 999;
	${flex()}
`;

const Box = styled.div`
	border: 1px solid ${({ theme }) => theme.player.box.border};
	border-radius: 6px;
	width: 28.5rem;
	padding: 1rem;
	background-color: ${({ theme }) => theme.player.box.bg};
`;

const Text = styled.p`
	font-size: ${({ theme }) => theme.fontSize.player.box.text}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.player.box.text};
	padding: 2rem;
	text-align: center;
	opacity: 0.8;
`;

const Controllers = styled.div`
	display: flex;
	justify-content: space-between;
`;

Resume.propTypes = {
	currentTime: number.isRequired,
	toggle: func.isRequired
};

export default Resume;
