// Import variables
import { FOOTER_PATHS, DOCUMENTS_PATHS } from 'components/routes/paths';

const { REGULATIONS, PRIVACY_POLICY } = DOCUMENTS_PATHS;

const { CONTACT } = FOOTER_PATHS;

export const FOOTER_NAV_LINKS = [
	{
		path: REGULATIONS,
		text: 'common_footer_terms_conditions',
		ariaLabel: 'aria_footer_nav_regulations'
	},
	{
		path: PRIVACY_POLICY,
		text: 'common_footer_privacy_policy',
		ariaLabel: 'aria_footer_nav_private_policy'
	},
	{
		path: CONTACT,
		text: 'common_footer_contact',
		ariaLabel: 'aria_footer_nav_private_contact'
	}
	//  ========= TEMPORARILY HIDDEN =========
	// {
	// 	path: COOKIES_POLICY,
	// 	text: 'common_footer_cookies_policy',
	// 	ariaLabel: 'aria_footer_nav_cookies_policy'
	// },
	// {
	// 	path: FAQ,
	// 	text: 'common_footer_faq',
	// 	ariaLabel: 'aria_footer_nav_private_faq',
	// },
	// {
	// 	path: ABOUT_US,
	// 	text: 'common_footer_about',
	// 	ariaLabel: 'aria_footer_nav_about_us'
	// },
];
