import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import components
import { Button } from 'components/elements';

// Import Icons
import { ReactComponent as TrashIcon } from 'assets/icons/ic_delete.svg';

// Import styles
import { transitionMain, flex } from 'components/styles';

export const AuthWrapper = styled.div`
	${flex()}
	padding: 3rem 1.5rem;
`;

// Favourite and Reminder common styles

export const Wrapper = styled.div`
	padding: 2.1rem 1rem 2.1rem 2.8rem;
	height: 8.6rem;
	border-bottom: 1px solid ${({ theme }) => theme.line};
	${flex({ jc: 'space-between', ai: 'flex-start' })}
	${transitionMain}
  &:hover {
		background-color: ${({ theme: { sidebar } }) => sidebar.itemHoverBg};
	}
`;

export const ProductTitle = styled.p`
	color: ${({ theme }) => theme.sidebar.itemColor};
	font-weight: ${({ theme }) => theme.font.bold};
	font-size: ${({ theme }) => theme.fontSize.sidebar.productTitle}rem;
	line-height: 2.4rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ProductType = styled.span`
	font-size: ${({ theme }) => theme.fontSize.sidebar.productType}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	color: ${({ theme }) => theme.sidebar.itemDescriptionColor};
`;

export const StyledLink = styled(Link)`
	display: block;
	width: 22.8rem;
	cursor: pointer;

	&:hover {
		${ProductTitle} {
			color: ${({ theme }) => theme.sidebar.itemColorHover};
		}
	}

	:focus-visible {
		${ProductTitle} {
			color: ${({ theme }) => theme.sidebar.itemColorHover};
		}
	}
`;

export const TrashIconStyled = styled(TrashIcon)`
	path {
		fill: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		stroke: ${({ theme: { sidebar } }) => sidebar.removeIcon};
		${transitionMain};
	}
`;

export const ButtonStyled = styled(Button)`
	&:hover {
		${TrashIconStyled} {
			path {
				fill: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
				stroke: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
			}
		}
	}

	&:focus-visible {
		${TrashIconStyled} {
			path {
				fill: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
				stroke: ${({ theme: { sidebar } }) => sidebar.removeIconHover};
			}
		}
	}
`;
