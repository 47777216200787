import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
	&.ant-menu {
		background-color: ${({ theme }) => theme.body.nav.menuMobileBgColor};
	}
	&.ant-menu-inline,
	.ant-menu-vertical,
	.ant-menu-vertical-left {
		border-right: none;
	}
`;
