import styled, { css } from 'styled-components';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';

// Import styles
import { flex } from 'components/styles';

const { SubMenu, Item } = Menu;

const baseText = css`
	font-size: ${({ theme }) => theme.fontSize.nav.menuMobileItem}rem;
	line-height: 1.9rem;
	font-weight: ${({ theme }) => theme.font.medium};
	color: ${({ theme }) => theme.body.nav.color};
	&::first-letter {
		text-transform: capitalize;
	}
	&:focus-visible {
		color: ${({ theme }) => theme.body.nav.focus};
	}
`;

export const StyledSubMenu = styled(SubMenu)`
	.ant-menu-submenu-title {
		${baseText}
		height: 7.5rem !important;
		${flex({ jc: 'space-between' })}
		padding: 2.7rem 2.5rem 2.9rem 3rem !important;
		margin: 0;
		border-bottom: 1px solid ${({ theme }) => theme.line};

		&:active {
			background-color: ${({ theme }) =>
				theme.body.nav.menuMobileBgColorActive};
		}
	}

	&.ant-menu-submenu-open {
		div svg {
			transform: rotate(180deg);
		}
	}

	&.ant-menu-submenu > .ant-menu {
		background-color: ${({ theme }) => theme.body.nav.menuMobileBgColor};
	}
`;

export const StyledMenuItem = styled(Item)`
	cursor: pointer;
	${flex({ jc: 'flex-start' })}
	&.ant-menu-item {
		${baseText}
		height: 5.5rem !important;
		line-height: 5.5rem !important;
		padding: 0 0 0 6rem !important;
		border-bottom: 1px solid ${({ theme }) => theme.line};
		margin: 0 !important;
		&::after {
			border-right: none;
		}
		&:active {
			background-color: ${({ theme }) =>
				theme.body.nav.menuMobileBgColorActive};
		}
	}
	&.ant-menu-item > a {
		font-size: ${({ theme }) => theme.fontSize.nav.submenuMobileItem}rem;
		line-height: 2.1rem;
		color: ${({ theme }) => theme.body.nav.color};
	}
`;

export const StyledLink = styled(Link)`
	&:link,
	&:visited {
		${baseText}
	}
`;

export const StyledAnchorLink = styled.a`
	${baseText}
`;
