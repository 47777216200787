import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import { baseInputStyles } from 'components/elements/atoms/Input';

export const DatePickerStyled = styled(DatePicker)`
	${baseInputStyles}
	padding: 0;
	height: 4.4rem;
	border: 1px solid ${({ theme }) => theme.authentication.input.borderColor};
	color: ${({ theme }) => theme.authentication.input.color};
	font-size: ${({ theme }) => theme.fontSize.set('xs', -0.2)}rem;

	&:focus,
	&:hover,
	&:focus-within {
		border-color: ${({ theme, isErrorVisible }) =>
			isErrorVisible
				? theme.error.border
				: theme.authentication.input.focusBorderColor};
	}

	&:focus,
	&:focus-within {
		.react-date-picker__inputGroup__input,
		.react-date-picker__inputGroup__divider {
			/* SHOW AND HIDE DD/MM/YYYY INPUTS */
			opacity: 1;
		}
	}

	.react-date-picker {
		&__inputGroup {
			text-align: left;
			padding-left: 2rem;
			&__input {
				/* SHOW AND HIDE DD/MM/YYYY INPUTS */
				opacity: ${({ value }) => (value ? 1 : 0)};
				/* COLOR AND FOCUS OF DD/MM/YYYY INPUTS AND CALENDAR ICON */
				border: none;
				&:focus {
					outline: none;
				}
				&:invalid {
					background: transparent;
				}
			}
			&__divider {
				/* SHOW AND HIDE DD/MM/YYYY INPUTS */
				opacity: ${({ value }) => (value ? 1 : 0)};
			}
		}

		&__calendar {
			/* calendar picker always pops up under field
			- there was case that that it showed over field
			 when browser window was short*/
			top: 4.2rem !important;

			/* for proper width on mobile */
			width: 100% !important;

			&--open {
				/* Calendar picker must be on top of field labels */
				z-index: 2;
			}
		}

		&__calendar-button {
			padding: 0 1.2rem;
			&__icon {
				stroke: ${({ theme }) => theme.authentication.input.color};
			}
			&:focus,
			&:hover {
				.react-date-picker__calendar-button__icon {
					stroke: ${({ theme }) =>
						theme.authentication.input.focusBorderColor} !important;
					transition: stroke ease-in-out 0.15s;
				}
			}
			&:focus {
				border-radius: 2pt;
				box-shadow: 0 0 0 1pt
					${({ theme }) => theme.authentication.input.focusBorderColor};
				outline: none;
			}
		}

		&__wrapper {
			border: none;
		}
	}

	button {
		&:not(.react-calendar__tile:disabled):not(.react-date-picker__calendar-button):not(.react-calendar__tile--now) {
			border-radius: 4px;
			&:focus,
			&:hover {
				background-color: ${({ theme }) =>
					theme.authentication.calendar.tileHoverColor} !important;
			}
		}
	}

	.react-calendar {
		position: relative;
		background-color: ${({ theme }) =>
			theme.authentication.calendar.calendarBg};

		border: 1px solid
			${({ theme }) => theme.authentication.calendar.calendarBorderColor};
		border-radius: ${({ theme }) =>
			theme.authentication.calendar.calendarBorderRadius}rem;
		width: 100%;

		&::before {
			content: '';
			width: 110%;
			height: 100%;
			background-color: ${({ theme, isMobile }) => {
				const labelBgOnMobile = isMobile
					? 'labelBgOnMobile'
					: 'labelBgOnDesktop';
				return theme.authentication[labelBgOnMobile];
			}};
			position: absolute;
			z-index: -1;
			top: 0;
			left: -5%;
		}

		&__tile {
			&--active {
				background-color: ${({ theme }) =>
					theme.authentication.calendar.tileActiveColor};
				color: ${({ theme }) =>
					theme.authentication.calendar.tileActiveTextColor};
			}
			&--now {
				border-radius: 4px;
				color: ${({ theme }) => theme.authentication.calendar.todayTextColor};
				background-color: transparent !important;

				&:hover {
					background-color: ${({ theme }) =>
						theme.authentication.calendar.tileHoverColor} !important;
					color: ${({ theme }) =>
						theme.authentication.calendar.todayHoverTextColor};
				}
			}
			&:disabled {
				color: ${({ theme }) =>
					theme.authentication.calendar.tileDisabledTextColor};
				background-color: ${({ theme }) =>
					theme.authentication.calendar.tileDisabledColor};
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;

				/* ONLY FIRST OF CLASS NEED TO HAVE ROUNDED BORDER */
				~ .react-calendar__tile:disabled {
					border-radius: inherit;
				}
			}
		}

		&__navigation {
			&__next-button,
			&__next2-button,
			&__prev-button,
			&__prev2-button {
				&:disabled {
					background-color: ${({ theme }) =>
						theme.authentication.calendar.tileDisabledColor} !important;
					color: ${({ theme }) =>
						theme.authentication.calendar.tileDisabledTextColor};
				}
			}
		}

		&__month-view {
			&__weekdays {
				&__weekday {
					abbr {
						text-decoration: none;
					}
				}
			}
		}
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: ${({ theme }) => theme.authentication.input.color};
		transition: background-color 5000s ease-in-out 0s;
	}

	/* remove up and down arrows in firefox */
	input[type='number'] {
		-moz-appearance: textfield;
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
`;
