import React from 'react';
import { number, array, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

// Import styles
import {
	Informations,
	InfoBox,
	Content,
	Divider,
	Author,
	Title,
	AssetNumber
} from '../styles';

// Import helpers
import { getAssetInfo } from '../helpers';

const Description = ({
	assets,
	index,
	assetsInGallery,
	galleryMetadata,
	isLightBackground,
	isFullscreen
}) => {
	const { t } = useTranslation();

	const currentAsset = assets[index];

	if (!currentAsset) return null;

	const { author, title, source } = getAssetInfo({
		...currentAsset,
		galleryMetadata
	});

	const showAssetNumber = assetsInGallery > 1;

	return (
		<Informations
			isFullscreen={isFullscreen}
			isLightBackground={isLightBackground}
		>
			{title && <Title>{title}</Title>}
			<Content>
				<InfoBox>
					{showAssetNumber && (
						<>
							<AssetNumber>
								{index + 1} / {assetsInGallery}
							</AssetNumber>
						</>
					)}
				</InfoBox>

				<Author
					isLightBackground={isLightBackground}
					isFullscreen={isFullscreen}
				>
					{author && (
						<>
							<span>
								{t('vod_movieDetails_gallery_author')} {author}
							</span>
							{source && <Divider>/</Divider>}
						</>
					)}
					{source && <span>{ReactHtmlParser(source)}</span>}
				</Author>
			</Content>
		</Informations>
	);
};

Description.propTypes = {
	assets: array,
	galleryMetadata: object,
	index: number,
	assetsInGallery: number,
	isLightBackground: bool,
	isFullscreen: bool
};

export default Description;
