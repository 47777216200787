import React from 'react';
import { arrayOf, oneOfType, node } from 'prop-types';

// Import styled components
import { Wrapper } from './styles';

// Import variables
import { SECTIONS_ID } from 'helpers/variables';

const { NAVIGATION } = SECTIONS_ID;

const NavTemplate = ({ children }) => {
	return <Wrapper id={NAVIGATION}>{children}</Wrapper>;
};

NavTemplate.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired
};

export default NavTemplate;
