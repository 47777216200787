import React from 'react';
import PropTypes from 'prop-types';
import { Popover as AntdPopover } from 'antd';
import { useSelector } from 'react-redux';

// Import helpers
import { getImgUrl } from 'helpers/index';

// Import utilities
import { ImageOnLoadRPC } from 'components/utilities';

// Import components
import PopoverContent from './PopoverContent/PopoverContent';
import PopoverToolbox from './toolbox/PopoverToolbox';
import NoImage from 'components/views/vod/images/placeholder.svg';
import {
	PopoverWrapper,
	Container,
	PlaceholderContainer,
	PlaceholderText,
	Preview,
	Image
} from './styles';

const popoverSettings = {
	placement: 'bottomLeft',
	trigger: 'hover',
	overlayClassName: 'epg-popover',
	destroyTooltipOnHide: true
};

const Popover = ({
	linkDetails,
	children,
	program,
	dragged = false,
	isUpcoming = false,
	isNotEnded = false,
	noPrograms,
	isCatchup,
	startDate = '',
	isLive = true,
	isContentBlocked,
	placeholderRatingText
}) => {
	const reminders = useSelector((store) => store.reminders.data);
	// If there is not any program, return null
	if (!program) return null;
	// Get program's images and screenshots
	const { images, screenshots, uuid: programId } = program;

	// Check image url, check if img's url exists
	const { imgUrl, isImgUrlValid } = getImgUrl({
		screenshots,
		images,
		isLive
	});

	// gets reminder object for its program
	const reminderForProgram = reminders.find(
		({ product_uuid }) => product_uuid === program.uuid
	);

	const reminderId = reminderForProgram ? reminderForProgram.id : false;

	const content = (
		<PopoverWrapper dragged={dragged}>
			<Container>
				{isContentBlocked ? (
					<PlaceholderContainer>
						<PlaceholderText>{placeholderRatingText}</PlaceholderText>
					</PlaceholderContainer>
				) : (
					<PopoverContent isCatchup={isCatchup} {...program} />
				)}
				<PopoverToolbox
					reminderId={reminderId}
					linkDetails={linkDetails}
					programId={programId}
					isUpcoming={isUpcoming}
					isCatchup={isCatchup}
					isNotEnded={isNotEnded}
					startDate={startDate}
				/>
			</Container>
			{isImgUrlValid && (
				<ImageOnLoadRPC>
					{({ setError, setLoaded, isLoaded }) => (
						<Preview>
							<Image
								isLoaded={isLoaded}
								onError={setError}
								src={isContentBlocked ? NoImage : imgUrl}
								onLoad={setLoaded}
								alt="Preview"
							/>
						</Preview>
					)}
				</ImageOnLoadRPC>
			)}
		</PopoverWrapper>
	);

	return !noPrograms ? (
		<AntdPopover content={content} {...popoverSettings}>
			{children}
		</AntdPopover>
	) : (
		children
	);
};

Popover.propTypes = {
	program: PropTypes.object.isRequired,
	dragged: PropTypes.bool,
	isUpcoming: PropTypes.bool,
	isCatchup: PropTypes.bool,
	isNotEnded: PropTypes.bool,
	linkDetails: PropTypes.shape({
		pathname: PropTypes.string,
		state: PropTypes.shape({ sCatchup: PropTypes.bool }),
		hash: PropTypes.string
	}),
	startDate: PropTypes.string,
	isContentBlocked: PropTypes.bool.isRequired,
	placeholderRatingText: PropTypes.string.isRequired,
	noPrograms: PropTypes.bool.isRequired,
	isLive: PropTypes.bool,
	children: PropTypes.array.isRequired
};

export default Popover;
