import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';

// Import components
import { Button } from 'components/elements';

// Import styles
import { customMinResponsive, flex, size } from 'components/styles';

export const NavToolbarStyled = styled.ul`
	list-style: none;
	display: flex;
`;

export const Item = styled.li`
	${flex()};
	color: ${({ theme: { body } }) => body.nav.icon};
	${({ withLine }) =>
		withLine &&
		css`
			position: relative;
			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 50%;
				-webkit-transform: translate(0, -50%);
				-ms-transform: translate(0, -50%);
				transform: translate(0, -50%);
				height: 2.7rem;
				width: 0.1rem;
				background-color: white;
			}
		`};

	${customMinResponsive(
		size.medium,
		css`
			&:not(:last-child) {
				width: 4rem;
			}
		`
	)};

	${customMinResponsive(
		size.large,
		css`
			&:not(:last-child) {
				width: 5rem;
			}

			&:last-child {
				margin-left: 1rem;
			}
		`
	)};
`;

export const StyledToolbarLink = styled(Link)`
	margin: 0 2rem;
`;

export const StyledButton = styled(Button)`
	margin-left: 2.1rem;
	max-width: 11.8rem;
	font-weight: ${({ theme }) => theme.font.medium};
	line-height: 2.3rem;
	border-radius: ${({ theme }) => theme.globalButtons.roundSize};
	padding: 0.7rem 1.4rem;
`;
