import { APP_CORE_PATHS } from './app';

const { PAGE } = APP_CORE_PATHS;

export const VOD_PATHS = {
	PLAYER: `/${PAGE}/ogladaj/:type/:id`,
	SECTION_DETAIL_PAGE: `/${PAGE}/:name/sekcja/:sectionId/:sectionName`,
	PRODUCT_DETAILS: `/${PAGE}/:name/:type/:id/:title`,
	CATEGORY_PAGE: `/${PAGE}/:name/kategorie`,
	SECTIONS_GROUPS_PAGE: `/${PAGE}/:name`
};
