import * as polished from 'polished';

// Import styles
import { colors } from './colors';
import fonts from './fonts';

export const mainTheme = {
	...colors,
	...fonts,

	/* General global styles for buttons */
	/* [Button, LINK_MODIFIERS -> Link, AuthButtonStyled, ButtonClose, StyledButton -> NavToolbar, AntModal, GlobalStyle] */
	globalButtons: {
		primary: {
			color: colors.white,
			bgColor: colors.primary,
			borderColor: colors.primary,
			hoverBgColor: colors.secondary,
			hoverBorderColor: colors.secondary,
			focusBgColor: colors.secondary
		},
		secondary: {
			color: colors.black,
			bgColor: colors.white,
			borderColor: colors.white,
			hoverBgColor: colors.semiDarkWhite,
			hoverBorderColor: colors.semiDarkWhite,
			focusBgColor: colors.semiDarkWhite,
			focusBorderColor: colors.semiDarkWhite
		},
		mobile: {
			color: colors.primary,
			colorHover: colors.white
		},
		showAll: {
			color: colors.white,
			colorHover: colors.contrastPrimary
		},
		roundSize: 0.1
	},

	/* Global styles for body main sections */
	body: {
		/* Main background colors */
		bg: {
			primary: colors.black,
			secondary: colors.black,
			third: colors.black
		},
		/* Navigation with WCAG options [StyledNav -> TabNav -> StyledHeader] */
		tabNav: {
			bg: colors.primary,
			color: colors.white,
			borderColor: colors.primary,
			shadowColor: colors.primary
		},
		/* Main navigation [Nav] */
		nav: {
			color: colors.white,
			hover: colors.contrastPrimary,
			focus: colors.contrastPrimary,
			bgColor: colors.semiLightBlack,
			icon: colors.white,
			iconActive: colors.contrastPrimary,
			menuActiveColorDot: colors.contrastPrimary,
			menuMobileBgColor: colors.black,
			menuMobileBgColorActive: colors.veryLightBlack,
			heightOnMobile: 7,
			heightOnDesktop: 9.2
		},
		/* [DisabilityNavWrapper, NavTemplate] */
		disabilityNav: {
			height: 0,
			textColor: colors.white,
			bgColor: colors.black
		},
		/* [Footer] */
		footer: {
			color: colors.gray,
			bg: colors.semiLightBlack,
			link: colors.white,
			hover: colors.contrastPrimary,
			social: colors.contrastPrimary,
			socialHover: colors.white,
			separatingLine: colors.darkWhite
		},
		/* Alert popup [AlertWrapper -> Alert] */
		alert: {
			border: colors.red,
			bg: colors.semiDarkWhite,
			color: colors.red
		}
	},

	/* Various elements general styling */
	general: {
		/* [GlobalStyle] */
		link: colors.white,
		linkHover: colors.contrastPrimary,
		/* [sectionBackground, scroll ] */
		shadowColor: polished.rgba(colors.black, 0.3),
		/* [Type -> Paragraph] */
		paragraph: {
			color: colors.veryLightGray
		}
	},

	/* Default styles for agreements */
	/* [Agreement, UserApproval] */
	agreements: {
		titleColor: colors.gray,
		descriptionColor: colors.gray,
		nameColor: colors.gray
	},

	/* Default styles for errors */
	error: {
		color: colors.red,
		border: colors.red,
		player: {
			iconColor: colors.gray,
			headingColor: colors.gray
		},
		img: {
			altTextColor: colors.white
		}
	},

	/* Default styles for outlines */
	outline: {
		/* [Button -> HeaderIcon, Checkbox -> Toggler, Heading ->TopHeadline] */
		focusColor: colors.darkWhite
	},

	/* Default styles for graphic lines */
	line: colors.veryLightBlack,

	/* Dropdown elements styling */
	dropdown: {
		/* Colors of select [GlobalStyle, SearchBoxStyled]*/
		textColor: colors.gray,
		arrowColor: colors.gray,
		arrowHoverColor: colors.white,
		borderColor: colors.white,
		borderHoverColor: colors.white,
		borderActiveColor: colors.white,
		bg: colors.black,

		/* Properties of select [GloblaStyle] */
		borderRadius: 0.1,

		/* Colors of list and list items */
		/* [ItemStyled -> NavDropdown, Globalstyle (antd overwrite style), ButtonLogout] */
		menu: {
			bg: colors.black,
			borderColor: colors.gray,
			borderRadius: 0.1,
			itemColor: colors.gray,
			itemHoverColor: colors.contrastPrimary,
			itemHoverBg: colors.black,
			itemActiveColor: colors.white,
			itemActiveBg: colors.black
		},

		/* Submenus styling [GlobalStyle, SubNavItem] */
		submenu: {
			textColor: colors.gray,
			textColorOnHover: colors.contrastPrimary,
			bgColor: colors.black
		},

		/* [BUTTON_MODIFIERS, NavDropdown] */
		button: {
			bg: colors.primary,
			color: colors.darkWhite,
			colorHover: colors.contrastPrimary
		}
	},

	/* Sidebar [DrawerStyled] */
	sidebar: {
		bg: colors.black,
		itemColor: colors.white,
		itemColorHover: colors.contrastPrimary,
		itemHoverBg: colors.semiLightBlack,
		itemDescriptionColor: colors.gray,
		removeIcon: colors.white,
		removeIconHover: colors.contrastPrimary,
		closeIcon: colors.white,
		closeIconHover: colors.contrastPrimary
	},

	/* General modal styles [AriaBaseModal, CookieBanner, antModal, Institutions] */
	modal: {
		bg: colors.black,
		textColor: colors.white,
		titleColor: colors.white,
		borderColor: colors.gray,
		transparentBorderColor: polished.rgba(colors.semiDarkWhite, 0.15),
		closeButton: colors.white,
		closeButtonHover: colors.contrastPrimary,
		link: colors.secondary,
		linkHover: colors.white
	},

	/* Login and register */
	authentication: {
		contentBg: colors.transparent,
		headingColor: colors.white,
		headingWrapperMobile: colors.semiLightBlack,
		infoColor: colors.white,
		linkColor: colors.contrastPrimary,
		linkHoverColor: colors.white,
		lineColor: colors.gray,
		labelBgOnDesktop: colors.black,
		labelBgOnMobile: colors.black,
		agreements: {
			color: colors.white,
			checkboxBorderColor: colors.gray,
			checkboxIconColor: colors.white,
			obligatoryPrefixColor: colors.white,
			modal: {
				color: colors.white
			}
		},
		input: {
			color: colors.gray,
			focusColor: colors.white,
			topLabelColor: colors.white,
			borderColor: colors.white,
			focusBorderColor: colors.white
		},
		calendar: {
			calendarColor: colors.white,
			calendarBg: colors.black,
			calendarBorderColor: colors.gray,
			calendarBorderRadius: 0.1,
			tileHoverColor: colors.white,
			tileActiveColor: colors.white,
			tileActiveTextColor: colors.gray,
			tileDisabledColor: colors.transparent,
			tileDisabledTextColor: colors.gray,
			todayTextColor: colors.semiDarkWhite,
			todayHoverTextColor: colors.gray,
			selectedDayColor: colors.black
		},
		tooltip: {
			border: polished.rgba(colors.black, 0.95),
			background: polished.rgba(colors.black, 0.95)
		}
	},

	/* User tab after loging */
	user: {
		/* Navigation */
		nav: {
			menuText: colors.gray,
			menuBG: colors.transparent,
			menuHoverText: colors.contrastPrimary,
			menuHoverBg: colors.transparent,
			menuActiveText: colors.white,
			menuActiveBg: colors.transparent,
			menuActiveHoverBg: colors.transparent
		},

		/* Main heading */
		topHeading: colors.white,
		topHeadingFilter: colors.gray,
		line: colors.gray,

		/* Table */
		tableItemName: colors.white,
		tableItemHover: colors.contrastPrimary,
		tableItemValue: colors.gray,
		removeIcon: colors.white,
		removeIconHover: colors.contrastPrimary,
		tableInfoColor: colors.veryLightGray,
		tableTypeColor: colors.veryLightGray,
		tableTimeColor: colors.veryLightGray,
		tableScroll: {
			track: colors.semiLightBlack,
			thumb: colors.veryLightBlack
		},

		/* Tab of general */
		generalLabel: colors.gray,
		baseInfo: {
			form: {
				buttonColor: colors.contrastPrimary,
				buttonHoverColor: colors.white,
				buttonFocusBorder: colors.white,
				inputColor: colors.white,
				inputBorder: colors.gray,
				inputFocusBorder: colors.white
			},
			email: colors.white,
			emailConfirmation: colors.gray
		},

		/* Agreements tab */
		agreements: {
			description: colors.gray,
			requiredNote: colors.gray,
			obligatoryPrefixColor: colors.gray,
			button: colors.contrastPrimary,
			buttonHover: colors.white,
			buttonFocusBorder: colors.white,
			itemBorder: polished.rgba(colors.white, 0.2),
			modal: {
				titleColor: colors.darkWhite,
				textColor: colors.darkWhite
			}
		},

		/* Tab of parental control */
		parentalControl: {
			status: colors.gray,
			statusValue: colors.white
		},

		/* Others */
		showChannel: colors.gray,
		tags: {
			vodInProfile: colors.primary
		},
		info: colors.gray
	},

	/* Default calendar settings [GlobalStyle] */
	calendar: {
		bg: colors.black,
		border: colors.gray,
		dayColor: colors.gray,
		dayHoverColor: colors.black,
		dayHoverBg: colors.primary,
		dayNameColor: colors.white,
		dayNameHoverColor: colors.gray,
		todayColor: colors.white,
		todayBg: colors.transparent,
		todayBorder: colors.transparent,
		disabledDayColor: colors.white,
		disabledDayBg: colors.gray,
		disabledDayBgHover: colors.veryLightGray,
		disabledDayColorHover: colors.white,
		selectedDayColor: colors.black,
		selectedDayBg: colors.white,
		selectedDayBorder: colors.white
	},

	/* Styles related to payment functionalities */
	payment: {
		/* [ProductPaymentTemplate, ProductTitle -> Headline, PaymentButton, PaymentInfo , Label -> Agreement,
			[AgreementsInfo -> ObligationToPay, PaymentField ] */
		background: colors.black,
		backButton: colors.white,
		backButtonHover: colors.contrastPrimary,
		heading: colors.white,
		oldPrice: polished.rgba(colors.black, 0.55),
		paymentInfo: colors.gray,
		loader: {
			bg: polished.rgba(colors.black, 0.5)
		},
		agreements: {
			color: colors.gray,
			link: colors.contrastPrimary,
			linkHover: colors.white
		},
		payInfo: colors.gray,
		/* [PaymentField, PaymentOption, GradientRadial -> Payment templates, PaymentOptions] */
		paymentOptions: {
			bg: colors.semiLightBlack,
			borderColor: polished.rgba(colors.white, 0.15),
			borderColorMobile: polished.rgba(colors.white, 0.2),
			voucher: {
				color: colors.semiDarkOrange
			},
			name: {
				color: colors.white
			},
			info: {
				color: colors.gray,
				fontWeight: fonts.font.normal
			},
			price: {
				color: colors.gray,
				fontWeight: fonts.font.normal
			},
			promotionPrice: {
				color: colors.darkGray
			},
			paymentField: {
				labelColor: colors.gray,
				color: colors.white,
				placeholderColor: colors.gray,
				borderColor: colors.gray
			},
			mobileHeader: colors.white,
			operatorRegulation: {
				description: colors.darkWhite,
				link: colors.contrastPrimary,
				linkHover: colors.white,
				scroll: {
					track: colors.semiLightBlack,
					thumb: colors.veryLightBlack
				}
			}
		},
		/* [BUTTON_MODIFIERS, Input -> Voucher] */
		voucher: {
			borderColor: colors.white,
			color: colors.gray,
			colorFocus: colors.white,
			roundSize: 0.1,
			submit: { background: colors.white, color: colors.black, roundSize: 0 }
		},
		emailNotConfirmed: {
			headingColor: colors.white,
			paragraph: {
				color: colors.white,
				fontWeight: fonts.font.light
			}
		}
	},

	/* Search */
	search: {
		/* [iconSearch, InputStyled -> SearchBar, InputStyled -> SearchInput] */
		closeIcon: colors.white,
		input: {
			bg: colors.transparent,
			color: colors.gray,
			mobileColor: colors.white,
			placeholderColor: colors.gray,
			mobilePlaceholderColor: colors.white,
			border: colors.gray,
			borderMobile: colors.white,
			borderFocus: colors.gray
		},
		/* [LinkStyled -> SearchItemChannel, SearchBoxStyled -> SearchBox, Clock -> VodSerialInfo, Clock -> ProgramInfo, SearchItem] */
		dropdown: {
			bg: colors.black,
			itemTitle: colors.white,
			itemTitleHover: colors.contrastPrimary,
			itemHoverBg: colors.black,
			itemDescription: colors.gray,
			shadow: polished.rgba(colors.black, 0.15),
			showAllLink: colors.white,
			showAllLinkHover: colors.contrastPrimary,
			itemInfo: colors.white,
			itemInfoHover: colors.contrastPrimary
		},
		/* [NavLink, SearchNav] */
		nav: {
			menuText: colors.gray,
			menuActiveText: colors.white,
			menuHoverText: colors.contrastPrimary,
			border: colors.gray
		},
		/* [SearchItem, SearchItemChannel] */
		channels: {
			bg: polished.rgba(colors.white, 0.07),
			itemHoverBorder: colors.veryDarkGray
		}
	},

	/* [HEADING_FIRST_MODIFIERS, ELLIPSIS_MODIFIERS ]*/
	heading: {
		first: {
			primary: colors.white,
			details: colors.veryLightGray,
			info: colors.veryLightGray
		}
	},

	/* Switch/Toggler styles [CheckBox, Toggler] */
	toggler: {
		bg: colors.gray,
		bgActive: colors.primary,
		control: colors.white,
		shadow: polished.rgba(colors.black, 0.2)
	},

	/* Radio Input styles [InputRadio -> Radio] */
	radio: {
		circle: {
			border: colors.primaryLight,
			bg: colors.primaryLight
		},
		label: {
			color: colors.gray
		}
	},

	/* [DashedLine -> ChannelsNav] */
	dashedLine: colors.gray,

	/* [DottedLoader, Loader] */
	loaders: {
		loader: {
			loaderBg: colors.black,
			colorStart: colors.primary,
			colorMiddle: colors.white,
			colorEnd: colors.primary
		},

		loaderButton: {
			primary: colors.black,
			secondary: colors.primary
		}
	},

	/* [getNavLinkUnderline -> FilterCategoryList, getNavLinkUnderline -> SidebarSearch ]*/
	activeGradientLine: {
		colorStart: colors.blue,
		colorEnd: colors.blue
	},

	/* [PopoverContent -> Popover, Popover -> GridItem, Popover -> ListItem, Popover -> EpgGridItem] */
	/* [GlobalStyle(epg), ButtonRecord -> ProgramRecordings] */
	popover: {
		bg: colors.black,
		border: colors.veryDarkGray,
		color: colors.gray,
		placeholder: colors.gray,
		title: colors.white,
		time: colors.white,
		genres: colors.gray,
		description: colors.white,
		link: colors.primary,
		linkHover: colors.white,
		icon: colors.white,
		iconActive: colors.primary,
		fallbackBg: colors.semiLightBlack
	},

	/* [GridItem -> ChannelType, ListItem -> ChannelType] */
	channels: {
		gridItemLinkHover: colors.white
	},

	/* EPG related styles */
	epg: {
		time: colors.white,
		timeMarkerBg: colors.gray,
		timeBigMarketBg: colors.gray,
		bg: colors.black,
		gridItemBg: colors.semiLightBlack,
		gridItemCatchupBg: colors.lightBlack,
		gridItemBorderHover: colors.contrastPrimary,
		gridItemShadow: colors.black,
		gridItemTitle: colors.white,
		gridItemCategory: colors.gray,
		nowBarBg: colors.contrastPrimary,
		catchup: {
			calendarColor: colors.semiDarkOrange,
			color: colors.white,
			bg: colors.semiDarkOrange,
			bgHover: colors.white
		},
		calendarText: colors.white
	},

	/* TV Programs styles */
	programDetails: {
		/* [IconClockStyled -> BasicItem]*/
		icon: colors.white,
		/* [TitleText -> Title] */
		title: colors.white,
		/* [Text -> BasicItem] */
		infoItemText: colors.white,
		/* [Descriptiontext -> Description] */
		description: colors.white,
		/* [Persons -> Cast] */
		personsType: colors.gray,
		personLink: colors.white,
		personLinkHover: colors.contrastPrimary,
		/* [Divider] */
		dividerBg: colors.white,
		/* [BroadcastItem -> Broadcast] */
		table: {
			row: {
				bg: colors.black,
				hoverBg: colors.secondary
			}
		}
	},

	/* Common gradients [GradientBg] */
	coverBg: {
		gradient: `linear-gradient(
			0deg,
			${polished.rgba(colors.black, 0.159348)},
			${polished.rgba(colors.black, 0.159348)}
		),
		radial-gradient(
			77.93% 77.93% at 1.25% 14.92%,
			${polished.rgba(colors.black, 0.702032)} 0%,
			${polished.rgba(colors.black, 0.0001)} 100%
		),
		linear-gradient(180deg, ${polished.rgba(colors.black, 0.0001)} 48.02%,
		${colors.black} 100%)`
	},

	/* Reminders on product/program details [Options -> RemindersMenu] */
	reminders: {
		menu: {
			roundSize: 0.1,
			color: colors.gray,
			colorHover: colors.contrastPrimary,
			border: colors.gray,
			bg: colors.black
		}
	},

	/* [MoviesGridList, ProductsGridList] */
	allMovies: {
		breadcrumbs: {
			color: polished.rgba(colors.white, 0.7)
		},
		menuButton: {
			color: colors.darkGray
		}
	},

	slider: {
		title: colors.white,
		arrow: {
			/* For slider with many images and titles */
			color: colors.white,
			hover: colors.contrastPrimary,
			focus: colors.contrastPrimary,
			/* For slider with one big image */
			sliderControllerBgColor: colors.black,
			sliderControllerBgHoverColor: colors.black,
			sliderControllerArrowHoverColor: colors.contrastPrimary
		},
		dot: {
			border: colors.white,
			bgHover: colors.contrastPrimary,
			borderHover: colors.contrastPrimary,
			bgActive: colors.white
		},
		available: {
			bgColorStart: colors.blue,
			bgColorEnd: colors.blue
		},
		/* Single slide/tile */
		item: {
			title: colors.white,
			description: colors.white,
			image: {
				borderFocus: colors.contrastPrimary,
				shadowFocus: colors.contrastPrimary,
				roundSize: {
					mobile: 0.4,
					desktop: 0.6
				}
			}
		}
	},

	/* Before loging welcome screen */
	landingPage: {
		registrationButton: {
			color: colors.white,
			bgContainerColor: colors.blue,
			borderColor: colors.veryLightBlack,
			bgDefault: {
				color1: colors.blue,
				color2: colors.blue
			},
			bgHover: {
				color1: colors.blue,
				color2: colors.blue
			}
		},
		/* [LandingPageDevices] */
		devices: {
			itemColor: colors.blue
		},
		titleLine: {
			border: polished.rgba(colors.white, 0.2)
		}
	},

	/* Styles related to product details presentation */
	product: {
		buttons: {
			/* [buttonsCommonStyles -> [StyledButton, StyledMenuButton]] */
			primaryColor: colors.white,
			secondaryColor: colors.black,
			primaryBg: colors.primary,
			secondaryBg: colors.white,
			primaryBorder: colors.primary,
			secondaryBorder: colors.white,
			primaryColorHover: colors.white,
			secondaryColorHover: colors.black,
			primaryBgHover: colors.secondary,
			secondaryBgHover: colors.semiDarkWhite,
			primaryBorderHover: colors.secondary,
			secondaryBorderHover: colors.semiDarkWhite,
			roundSize: 0.1,
			/* [TabButton] */
			tabButton: colors.gray,
			tabButtonSelected: colors.white,
			tabButtonHover: colors.contrastPrimary
		},
		remindersMenu: {
			background: colors.semiLightBlack,
			borderColor: polished.rgba(colors.white, 0.15)
		},
		lightBackground: {
			color: colors.semiLightBlack,
			background: colors.semiDarkWhite,
			link: colors.semiLightBlack,
			linkHover: colors.blue
		},
		coverBackground: {
			color: colors.white,
			background: colors.black,
			link: colors.white,
			linkHover: colors.contrastPrimary,
			detailItemTitle: colors.gray,
			galleryDescription: colors.gray,
			galleryItemRoundSize: 0.4,
			descriptionScrollColor: colors.primary,
			descriptionScrollBarColor: colors.gray,
			statuteColor: colors.gray
		},
		articleBlackout: {
			background: colors.darkWhite
		},
		/* [PacketsModalTemplate] */
		packetsModal: {
			bg: colors.semiLightBlack,
			border: polished.rgba(colors.white, 0.15),
			roundSize: 0.1,
			closeButton: colors.white,
			closeButtonHover: colors.contrastPrimary,
			itemRoundSize: 0.6,
			itemImageGradient: `linear-gradient(0deg, ${polished.rgba(
				colors.black,
				0.6
			)}, ${polished.rgba(colors.black, 0.6)})`,
			itemDescription: colors.white,
			screenBg: polished.rgba(colors.black, 0.5),
			scrollColor: colors.primary,
			scrollBarColor: colors.gray
		},
		/* [commonSVGStyles] */
		share: {
			iconColor: colors.white
		},
		/* [SeasonInfo, SeriesSeasons] */
		seasons: {
			titleColor: colors.white,
			noEpisodeInfoColor: colors.white
		},
		/* [Tag -> SearchItem] */
		tags: {
			channelBg: colors.blueOcean,
			vodBg: colors.blueDark,
			programBg: colors.turquoiseDark,
			seriesBg: colors.lightBlack,
			seasonBg: colors.pinkGray,
			episodeBg: colors.purpleDark,
			packetBg: colors.orangeNormal,
			provisionBg: colors.orangeNormal,
			collectionBg: colors.orangeNormal,
			mainBg: colors.orangeNormal,
			defaultBg: colors.blueGray
		}
	},

	cookies: {
		color: colors.white
	},

	institution: {
		social: {
			hover: colors.blue
		}
	},

	/* [baseInputStyles -> Inut] */
	input: {
		roundSize: 0.1,
		color: colors.gray,
		focusColor: colors.white,
		borderColor: colors.gray,
		focusBorderColor: colors.white
	},

	/* [Contact] */
	contact: {
		textTitle: colors.white,
		text: colors.gray
	},

	/* Player related styles */
	player: {
		volumeBg: colors.gray,
		volumeBar: colors.contrastPrimary,
		settingsBg: polished.rgba(colors.black, 0.8),
		settingsBorder: polished.rgba(colors.white, 0.1),
		settingsTitle: colors.white,
		settingsValue: colors.white,
		settingsValueHover: colors.contrastPrimary,
		settingsValueActive: colors.white,
		button: colors.gray,
		buttonHover: colors.contrastPrimary,
		dotBg: colors.contrastPrimary,
		dotBorder: colors.contrastPrimary,
		loadedStrapBg: colors.contrastPrimary,
		timer: colors.gray,
		/* Resume or next episode */
		box: {
			bg: colors.black,
			border: colors.white,
			text: colors.white,
			button: {
				roundSize: 0.1,
				color: colors.black,
				bgColor: colors.white,
				border: colors.gray,
				cancelColor: colors.black,
				cancelBgColor: colors.gray,
				cancelBorder: colors.gray
			}
		},
		parental: {
			button: {
				roundSize: 0.1,
				color: colors.black,
				bgColor: colors.white,
				border: colors.gray
			}
		},
		shaka: {
			bg: colors.black,
			seekBar: {
				border: polished.rgba(colors.white, 0.6),
				bg: colors.blue
			}
		}
	},

	/* Products on preview of all section items and preview of all search results [GridTile] */
	productItemsGrid: {
		tileTitle: colors.white,
		tileDescription: colors.white
	},

	/* [PortalUnavailable] */
	portalUnavailable: {
		color: colors.white,
		icon: colors.contrastPrimary
	},

	/* [Banner, HighBanners] */
	highBanners: {
		bg: colors.white,
		titleColor: colors.white,
		descriptionColor: colors.white
	},

	/* Styles, that overwrites external lib default styling */
	externalLib: {
		react_v: {
			collectionBg: colors.white
		}
	},

	notification: {
		bg: '#323232',
		color: colors.white
	}
};
