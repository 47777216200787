import React from 'react';
import { Switch } from 'react-router-dom';

// Import components
import ProtectedUserLogged from 'components/hoc/ProtectedUserLogged';
import ProtectedRegistrationCompleted from 'components/hoc/ProtectedRegistrationCompleted';
import { Login, Register } from './helpers/components';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import routes
import ProtectedRoutes from './ProtectedRoutes';

const { REGISTER, LOGIN, ADDITIONAL_INFO } = APP_CORE_PATHS;

const Routes = () => {
	return (
		<Switch>
			<ProtectedUserLogged
				path={`/${REGISTER}/${ADDITIONAL_INFO}`}
				component={Register}
			/>
			<ProtectedUserLogged path={`/${REGISTER}`} component={Register} />
			<ProtectedUserLogged path={`/${LOGIN}`} component={Login} />
			<ProtectedRegistrationCompleted component={ProtectedRoutes} />
		</Switch>
	);
};

export default Routes;
