import React from 'react';
import { bool } from 'prop-types';

// Import components
import Nav from 'components/layout/nav/Nav';
import MobileNav from '../nav/nav_mobile/MobileNav';
import TabNav from 'components/layout/tab_nav';

// Import styled components
import { StyledHeader } from './styles';

// Import hooks
import { useDeviceInfo } from 'components/utilities';

const Header = ({ showMainNav }) => {
	const { isMobileDevice } = useDeviceInfo();

	return (
		<StyledHeader>
			<TabNav />
			{showMainNav && <>{isMobileDevice ? <MobileNav /> : <Nav />}</>}
		</StyledHeader>
	);
};

Header.propTypes = {
	showMainNav: bool.isRequired
};

export default Header;
