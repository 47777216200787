import React from 'react';
import { string, number, array, oneOfType, shape } from 'prop-types';

// Import components
import {
	SliderSection,
	Banners as BannersSlider,
	HighBanners
} from 'components/elements';
import Carousel from 'components/views/tv/home/carousel/Carousel';

// Import helpers
import { SECTIONS_TYPES } from 'helpers/variables';

const { CAROUSEL, BANNER, HIGH_BANNER } = SECTIONS_TYPES;

const Section = React.memo(
	({ index, section }) => {
		if (!section?.content?.length) return null;

		const { id, type, name, content } = section;
		const isFirstSection = index === 0;

		switch (type) {
			case CAROUSEL:
				return (
					<Carousel content={content}>
						{({ changeCurrentIndex }) => (
							<SliderSection
								modifiers="carousel"
								content={content}
								type={type}
								id={id}
								name={name}
								renderSlideAsLink={false}
								onItemClick={changeCurrentIndex}
							/>
						)}
					</Carousel>
				);

			case HIGH_BANNER:
				return <HighBanners content={content} />;

			case BANNER:
				return <BannersSlider content={content} />;

			default:
				return (
					<SliderSection
						modifiers="sectionsList"
						content={content}
						type={type}
						id={id}
						name={name}
						addMarginTop={isFirstSection}
					/>
				);
		}
	},
	(prev, next) => prev.index === next.index
);

Section.propTypes = {
	index: number.isRequired,
	section: shape({
		id: oneOfType([number, string]).isRequired,
		type: string.isRequired,
		name: string.isRequired,
		content: array.isRequired
	}).isRequired
};

export default Section;
