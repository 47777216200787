import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive, flex, size } from 'components/styles';

export const Social = styled.div`
	margin: 2.2rem auto;
	${flex()}

	${customMinResponsive(
		size.medium,
		css`
			width: 100%;
		`
	)}
`;

export const AnchorStyled = styled.a`
	${flex()}

	&:not(:last-child) {
		margin-right: 1rem;
		${customMinResponsive(
			size.medium,
			css`
				margin-right: 1.6rem;
			`
		)}
	}

	svg {
		height: 2.5rem;
		width: 2.5rem;

		g {
			g {
				fill: ${({ theme }) => theme.body.footer.social};
				transition: fill 0.1s;
			}
		}
	}

	${customMinResponsive(
		size.medium,
		css`
			svg {
				height: 3.2rem;
				width: 3.2rem;
			}

			&:hover {
				svg {
					g {
						g {
							fill: ${({ theme }) => theme.body.footer.socialHover};
						}
					}
				}
			)}
		`
	)}

	&:focus-visible {
		svg {
			g {
				g {
					fill: ${({ theme }) => theme.body.footer.socialHover};
				}
			}
		}
	}
`;
