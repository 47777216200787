import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { number, string, object, array, func, bool } from 'prop-types';

// Import components
import { Toggler, Agreement } from 'components/elements';

// Import helpers
import { checkIsAgreementAccepted } from 'helpers/agreements';

// Import styles
import { AgreementName, AgreementDescription } from './styles';

const AgreementItem = ({
	id,
	name,
	version,
	obligatory,
	toggleAgreement,
	acceptedAgreements
}) => {
	const handleToggleAgreement = () => toggleAgreement(id);

	const isAccepted = checkIsAgreementAccepted({ acceptedAgreements, id });

	return (
		<Agreement.Item>
			<Toggler
				id={id}
				toggleAgreement={handleToggleAgreement}
				checked={isAccepted}
			/>
			<Agreement.Label
				htmlFor={id}
				obligatory={obligatory}
				isMarketingAgreement
			>
				<AgreementName>{name}</AgreementName>
				{version && (
					<AgreementDescription>
						{ReactHtmlParser(version.description)}
					</AgreementDescription>
				)}
			</Agreement.Label>
		</Agreement.Item>
	);
};

AgreementItem.propTypes = {
	id: number,
	name: string,
	version: object,
	obligatory: bool,
	toggleAgreement: func,
	acceptedAgreements: array
};

export default AgreementItem;
