import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useMobileNav = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isSearchingOpen, setIsSearchingOpen] = useState(false);

	const { pathname } = useLocation();

	const toggleMenu = () => {
		setIsMenuOpen((prevState) => !prevState);
	};

	const toggleSearching = () => {
		setIsSearchingOpen((prevState) => !prevState);
		setIsMenuOpen(false);
	};

	const applicationRoot = document.getElementById('root');

	const toggleAriaVisibility = useCallback(
		(bool) => applicationRoot.setAttribute('aria-hidden', bool),
		[applicationRoot]
	);

	// Hide mobile nav and search after route change
	useEffect(() => {
		setIsMenuOpen(false);
		setIsSearchingOpen(false);
	}, [pathname]);

	useEffect(() => {
		if (isMenuOpen || isSearchingOpen) {
			document.body.style.overflow = 'hidden';
			toggleAriaVisibility(true);
		} else {
			document.body.style.overflow = 'visible';
			toggleAriaVisibility(false);
		}

		// Toggle mobile nav and search after mobile/desktop change
		return () => (document.body.style.overflow = 'visible');
	}, [isMenuOpen, isSearchingOpen, toggleAriaVisibility]);

	return {
		toggleMenu,
		toggleSearching,
		isMenuOpen,
		isSearchingOpen
	};
};
