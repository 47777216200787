import React from 'react';
import styled from 'styled-components';

// Import helpers
import { coverImage } from '../../../helpers/coverImage';
import { getUserAgentInfo } from 'helpers';

const Cover = styled.div`
	width: 100%;
	height: 100%;
	max-height: 100%;
	background-image: ${({ cover }) =>
		cover ? `url(${cover}=w1920-h1080-c)` : 'none'};
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center top;
	background-size: cover;
	z-index: 2;
	position: absolute;
	top: 0;
	left: 0;
	opacity: ${({ shouldBeShown }) => (shouldBeShown ? '1' : '0')};
`;

export function CarouselBackground(props) {
	const { content, currentIndex } = props;

	const { isIOS } = getUserAgentInfo();

	return content.map((item, index) => {
		const shouldBeShown = currentIndex === index;

		if (isIOS && !shouldBeShown) return null;

		return (
			<Cover
				key={`${item.uuid}-${index}`}
				cover={coverImage(item.images)}
				shouldBeShown={shouldBeShown}
			/>
		);
	});
}
