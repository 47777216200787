import React from 'react';
import { func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Import logic
import useEpisodeItem from './useEpisodeItem';

// Import components
import { AuthModal, PaymentProtect } from 'components/elements';
import SlideImage from '../BasicSlide/SlideImage';

// Import styles
import {
	StyledButton,
	Title,
	IconPlay,
	IconBasket,
	IconLock,
	AvailabilityDate
} from 'components/elements/molecules/slider/SliderItem/styles';

const EpisodeItem = ({
	slideWidth,
	slideHeight,
	spaceBetweenSlides,
	product,
	index,
	setSelected,
	selected
}) => {
	const { t } = useTranslation();

	const {
		episodeCover,
		isSelected,
		episodeTitle,
		availablilityDate,
		showAvailabilityDate,
		isAvailableToPlay,
		isAvailableToBuy,
		episodeRef,
		isDisabled,
		episodeClick,
		episodeFocus,
		episodeHover
	} = useEpisodeItem({ product, index, setSelected, selected });

	const renderIcon = () => {
		if (isSelected) {
			if (isAvailableToPlay) return <IconPlay height={slideHeight} />;
			if (isAvailableToBuy) return <IconBasket height={slideHeight} />;
			return <IconLock height={slideHeight} />;
		}

		return null;
	};

	return (
		<AuthModal>
			<PaymentProtect>
				<StyledButton
					ref={episodeRef}
					onClick={episodeClick}
					onFocus={episodeFocus}
					onMouseEnter={episodeHover}
					marginright={spaceBetweenSlides}
					width={slideWidth}
					height={slideHeight}
					disabled={isDisabled}
				>
					<SlideImage slideHeight={slideHeight} src={episodeCover} />

					{episodeTitle && <Title>{episodeTitle}</Title>}

					{showAvailabilityDate && (
						<AvailabilityDate>
							{t('vod_product_available_from')} {availablilityDate}
						</AvailabilityDate>
					)}

					{renderIcon()}
				</StyledButton>
			</PaymentProtect>
		</AuthModal>
	);
};

EpisodeItem.defaultProps = {
	setSelected: () => {}
};

EpisodeItem.propTypes = {
	slideWidth: number.isRequired,
	slideHeight: number.isRequired,
	spaceBetweenSlides: number.isRequired,
	setSelected: func.isRequired,
	index: number.isRequired,
	selected: number.isRequired,
	product: object.isRequired
};

export default EpisodeItem;
