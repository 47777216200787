import React from 'react';
import { useTranslation } from 'react-i18next';

// Import styled components
import { Nav, Item, StyledLink } from './styles';

// import helpers
import { FOOTER_NAV_LINKS } from './helpers';

const FooterNav = () => {
	const { t } = useTranslation();

	const renderFooterNavLinks = FOOTER_NAV_LINKS.map(
		({ path, text, ariaLabel }) => (
			<Item key={text}>
				<StyledLink to={path} aria-label={t(ariaLabel)} title={t(text)}>
					{t(text)}
				</StyledLink>
			</Item>
		)
	);

	return <Nav>{renderFooterNavLinks}</Nav>;
};

export default FooterNav;
