import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'antd';

// Import icons
import { ReactComponent as BellIcon } from 'assets/icons/new_ic_bell.svg';

// Import components
import { ProgramRecordings, AuthModal } from 'components/elements';
import { RemindersMenu } from '../../common/RemindersMenu';
import Share from './share/Share';
import { ToolBox, Box, ButtonStyled, ReminderButtonStyled } from './styles';

// Import actions
import { deleteReminder } from 'store/actions';

// Import helpers
import { checkAppType } from 'helpers';
import { useTranslation } from 'react-i18next';

const PopoverToolbox = ({
	reminderId,
	linkDetails,
	programId,
	isUpcoming = false,
	isCatchup = false,
	isNotEnded = false,
	startDate
}) => {
	const { t } = useTranslation();

	const { data } = useSelector(({ devices }) => devices);
	const dispatch = useDispatch();
	const { isTvSmart } = checkAppType();

	const recordingConditions = isNotEnded && data.length > 0 && !isTvSmart;

	const showShareButton = false;

	return (
		<ToolBox>
			{isUpcoming && isNotEnded && (
				<Box>
					{reminderId ? (
						<ReminderButtonStyled
							onClick={() => deleteReminder(reminderId)(dispatch)}
							modifiers="secondary"
						>
							<BellIcon /> {t('vod_buttons_live_remove_reminder')}
						</ReminderButtonStyled>
					) : (
						<Dropdown
							trigger={['click']}
							overlay={() => (
								<RemindersMenu programId={programId} startDate={startDate} />
							)}
						>
							<AuthModal>
								<ReminderButtonStyled modifiers="secondary">
									<BellIcon />
									{t('vod_buttons_live_add_reminder')}
								</ReminderButtonStyled>
							</AuthModal>
						</Dropdown>
					)}
				</Box>
			)}
			{!isUpcoming && (
				<Box isCatchup={isCatchup}>
					<ButtonStyled as={Link} to={linkDetails} modifiers="default">
						{isNotEnded
							? t('vod_buttons_play')
							: t('vod_buttons_live_check_details')}
					</ButtonStyled>
				</Box>
			)}
			{recordingConditions && (
				<Box>
					<ProgramRecordings programId={programId} />
				</Box>
			)}
			{showShareButton && (
				<Box>
					<Share linkDetails={linkDetails} />
				</Box>
			)}
		</ToolBox>
	);
};

PopoverToolbox.propTypes = {
	isUpcoming: PropTypes.bool.isRequired,
	programId: PropTypes.string.isRequired,
	linkDetails: PropTypes.shape({
		pathname: PropTypes.string,
		state: PropTypes.shape({ sCatchup: PropTypes.bool }),
		hash: PropTypes.string
	}),
	reminderId: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([false])]),
	isCatchup: PropTypes.bool,
	isNotEnded: PropTypes.bool,
	startDate: PropTypes.string
};

export default PopoverToolbox;
