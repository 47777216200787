import React from 'react';
import { useSelector } from 'react-redux';

// Import components
import NavLinks from './NavLinks';
import NavToolbar from './nav_toolbar/NavToolbar';
import NavSearch from './nav_search/NavSearch';
import NavTemplate from './nav_template';
import PortalLogo from './portal_logo';

// Import utilities
import { ToggleRPC } from 'components/utilities';

const Nav = () => {
	const { menuLinks } = useSelector(({ menu }) => menu);

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<NavTemplate>
					<PortalLogo />
					{!on && <NavLinks menuNavLinks={menuLinks} />}
					{on && <NavSearch toggle={toggle} />}
					{!on && <NavToolbar toggle={toggle} />}
				</NavTemplate>
			)}
		</ToggleRPC>
	);
};

export default Nav;
