import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import icons
import { ReactComponent as IconArrowBack } from 'assets/icons/ic_back.svg';

// Import components
import { Button, AgeRatingIcon, ContentRatingIcons } from 'components/elements';
import { ButtonClose, FullScreenButton } from './components/Buttons';

// Import context
import { usePlayerVodContext } from 'components/context/player/player_vod';

// Import styles
import {
	Wrapper,
	Title,
	Controller,
	TitleWrapper,
	BasicInfoWrapper,
	RatingWrapper
} from './styles';

// Import helpers
import { getInappropriateContentTypes } from 'helpers';

// Import paths
import { APP_CORE_PATHS } from 'components/routes/paths';

const TopHeadline = React.memo(
	({ isFocus, isError }) => {
		const history = useHistory();
		const { t } = useTranslation();
		const playerMatch = useRouteMatch(APP_CORE_PATHS.WATCH);

		const movie = useSelector(({ movieDetails }) => movieDetails.data);
		const {
			data: { metadata }
		} = useSelector(({ movieDetails }) => movieDetails);
		const inappropriate_content =
			metadata?.additional_data?.inappropriate_content;

		const title = movie?.metadata?.title;

		const { isReady, isPreview, isFullScreen, closePreview, floatMode } =
			usePlayerVodContext();

		const { isFloatMode, closeFloatMode } = floatMode;

		const handleBack = () => (isPreview ? closePreview() : history.goBack());

		const isPlayerPage = !!playerMatch;

		const showTitle = isPlayerPage || isFullScreen || isPreview;

		const rating = metadata?.rating;

		const contentRating =
			inappropriate_content &&
			getInappropriateContentTypes(inappropriate_content);

		return (
			<Wrapper isFloatMode={isFloatMode} isFocus={isFocus || isError}>
				{isFloatMode && isFullScreen && <Title>{title}</Title>}

				<BasicInfoWrapper isFloatMode={isFloatMode}>
					{!isFloatMode && (
						<TitleWrapper>
							{isPlayerPage && (
								<Button
									modifiers={['icon', 'playerIconHover']}
									onClick={handleBack}
									title={t('common_back')}
									aria-label={t('common_back')}
								>
									<IconArrowBack aria-hidden="true" />
								</Button>
							)}
							{showTitle && <Title>{title}</Title>}
						</TitleWrapper>
					)}
					<RatingWrapper>
						<AgeRatingIcon rating={rating} />
						{contentRating && (
							<ContentRatingIcons contentRating={contentRating} />
						)}
					</RatingWrapper>
				</BasicInfoWrapper>

				<Controller isFullScreen={isFullScreen} isFloatMode={isFloatMode}>
					{!isError && isReady && !isPreview && <FullScreenButton />}

					{isPreview && <ButtonClose close={handleBack} />}

					{isFloatMode && !isFullScreen && (
						<ButtonClose close={closeFloatMode} />
					)}
				</Controller>
			</Wrapper>
		);
	},
	(prev, next) => JSON.stringify(prev) === JSON.stringify(next)
);

TopHeadline.propTypes = {
	isFocus: PropTypes.bool.isRequired,
	isError: PropTypes.bool.isRequired
};

export default TopHeadline;
