import styled, { css } from 'styled-components';

// Import icons
import { ReactComponent as IconPlaySVG } from 'assets/icons/play.svg';
import { ReactComponent as IconBasketSVG } from 'assets/icons/ic_basket.svg';
import { ReactComponent as IconLockSVG } from 'assets/icons/ic_lock_second.svg';
import { ReactComponent as IconTickCircleSVG } from 'assets/icons/ic_tick_circle.svg';
import { ReactComponent as IconEUPromoSVG } from 'assets/icons/ic_EU.svg';
import { ReactComponent as IconEUPLPromoSVG } from 'assets/icons/ic_EU_PL.svg';

// Import styles
import { itemFocus, textEllipsis } from 'components/styles';

// Import placeholder
import placeholder from 'assets/images/placeholder.png';

//Import styles
import { customMinResponsive, size } from 'components/styles';

const episodeIconStyles = css`
	width: 6rem;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: ${({ height }) => height / 10}rem;
`;

export const Figure = styled.figure`
	width: 100%;
	height: ${({ slideHeight }) => slideHeight / 10}rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border-radius: ${({ theme }) => theme.slider.item.image.roundSize.mobile}rem;
	background: no-repeat url(${placeholder}) center/cover;
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};
	margin-bottom: 0;

	${customMinResponsive(
		size.medium,
		css`
			border-radius: ${({ theme }) =>
				theme.slider.item.image.roundSize.desktop}rem;
		`
	)};
`;

export const Image = styled.img`
	height: 100%;
	width: ${({ isImageUrl }) => (isImageUrl ? '100%' : 'auto')};
	object-fit: cover;
	object-position: center;
	color: ${({ isError, theme }) =>
		isError ? theme.error.img.altTextColor : theme.transparent};
`;

export const Title = styled.h3`
	font-size: ${({ theme }) => theme.fontSize.product.media.title.mobile}rem;
	line-height: 2.1rem;
	color: ${({ theme }) => theme.slider.item.title};
	margin-top: 0.4rem;
	${textEllipsis}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.product.media.title.desktop}rem;
			line-height: 3.6rem;
		`
	)}
`;

export const Description = styled.p`
	font-size: ${({ theme }) =>
		theme.fontSize.product.media.productType.mobile}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	line-height: 1rem;
	text-transform: uppercase;
	color: ${({ theme }) => theme.slider.item.description};
	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.product.media.productType.desktop}rem;
			line-height: 2.8rem;
		`
	)}
`;

export const StyledButton = styled.button`
	border: none;
	background: transparent;
	text-align: left;
	margin-right: ${({ marginright = 1 }) => marginright / 10}rem;
	position: relative;
	display: block;
	width: ${({ width }) => width / 10}rem;
	cursor: pointer;

	&:focus-visible {
		outline: none;

		figure {
			${itemFocus()}
			cursor: ${({ cursor = 'auto' }) => cursor};
		}
	}

	&:hover {
		outline: none;

		figure {
			${itemFocus()}
		}
	}

	&:disabled {
		cursor: not-allowed;

		figure {
			cursor: not-allowed;
		}
	}

	&:focus {
		outline: none;
	}
`;

export const Divider = styled.span`
	margin: 0 4px;
`;

export const IconPlay = styled(IconPlaySVG)`
	${episodeIconStyles}
`;

export const IconBasket = styled(IconBasketSVG)`
	${episodeIconStyles}
`;

export const IconLock = styled(IconLockSVG)`
	${episodeIconStyles}
`;

export const IconTickCircle = styled(IconTickCircleSVG)`
	z-index: 1;
	width: 1.5rem;
	height: 1.5rem;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 2rem;
			height: 2rem;
			top: 1rem;
			right: 1rem;
		`
	)};
`;

const promoIconStyles = css`
	position: absolute;
	z-index: 1;
	width: 2.8rem;
	height: 2.8rem;
	top: 0.5rem;
	left: 0.5rem;

	${customMinResponsive(
		size.medium,
		css`
			width: 3.8rem;
			height: 3.8rem;
			top: 1rem;
			left: 1rem;
		`
	)};
`;

export const IconEUPromo = styled(IconEUPromoSVG)`
	${promoIconStyles}
`;

export const IconEUPLPromo = styled(IconEUPLPromoSVG)`
	${promoIconStyles}
`;

export const AvailabilityDate = styled.p`
	color: ${({ theme }) => theme.white};
	font-size: ${({ theme }) =>
		theme.fontSize.product.media.availabilityDate.mobile}rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.product.media.availabilityDate.desktop}rem;
		`
	)}
`;
