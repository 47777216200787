import styled from 'styled-components';
import { Menu } from 'antd';

// Import styles
import { flex } from 'components/styles';

// Import components
import { Button } from 'components/elements';

export const StyledButton = styled(Button)`
	margin-right: 0;
`;

export const StyledMenu = styled(Menu)`
	margin-top: 1rem;

	&.ant-dropdown-menu {
		padding: 1.5rem 3rem 3rem 3rem;
	}
`;

export const ButtonLogout = styled(Button)`
	font-size: ${({ theme }) => theme.fontSize.nav.logOutBtn}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme: { dropdown } }) => dropdown.menu.itemColor};
	${flex({ jc: 'left' })}
	background-color: transparent;
	padding: 0.5rem 1.2rem;
	width: 100%;

	&:hover,
	&:focus {
		color: ${({ theme: { dropdown } }) => dropdown.menu.itemHoverColor};
	}
`;
