import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	fetchPlayerConfig,
	deleteVideoSession,
	setSelectedProgram,
	setTvProgramsLive,
	playerOnCancel
} from 'store/actions';

// Import helpers
import { convertTime, getImgUrl } from 'helpers/index';

// Import utilities
import { useProgramTimeProgress, ImageOnLoadRPC } from 'components/utilities';

// Import components
import { Masonry, Paragraph, Progress } from 'components/elements';

// Import icons
import placeholderImg from 'assets/images/placeholder.png';

// Import hooks
import useProductRatingCheck from 'components/elements/molecules/parental_control/parental_control_utils/useProductRatingCheck';

// Import variables
import { PLAYER_SELECTORS } from 'helpers/variables';

const MasonryLive = ({
	element: { noPrograms, title: channelName, epgProgrammes, currentLiveIndex },
	index,
	adjustHeight,
	channelID,
	fetchPlayerConfig,
	deleteVideoSession,
	videoSessionId,
	setSelectedProgram,
	setTvProgramsLive,
	isSliderItem = false,
	playerOnCancel
}) => {
	const epg = epgProgrammes[currentLiveIndex];

	const {
		since,
		till,
		title,
		description_short,
		images,
		uuid: id,
		pc_rating
	} = epg;

	// check program rating and block content accordingly
	const { isContentBlocked, placeholderRatingText } = useProductRatingCheck(
		pc_rating,
		since
	);

	// Calculate the program's time progress
	const { progress } = useProgramTimeProgress(
		{ since, till },
		channelID,
		setTvProgramsLive
	);

	// Check image url, check if img's url exists
	const { imgUrl, isImgUrlValid } = getImgUrl({ images });

	const handlePlaySelected = async () => {
		if (!noPrograms) {
			// Delete request
			// Dispatch an playerOnCancel action in player folder
			playerOnCancel(PLAYER_SELECTORS.PLAYER);

			// Delete video live session
			// Dispatch an deleteVideoSession action in player folder
			videoSessionId && (await deleteVideoSession());

			// Set title in player overlay content
			// Dispatch an setSelectedProgram action in channel folder
			setSelectedProgram({ title, id, channel: channelName, channelID });

			// Scroll window to top
			window.scrollTo({ top: 0, behavior: 'smooth' });

			// Fetch new video session config
			// Dispatch an fetchPlayerConfig action in player folder
			fetchPlayerConfig({ productID: channelID });
		}
	};

	// set img url based on rating check and image url validity
	const setImgUrl = () => {
		if (isContentBlocked) return placeholderImg;
		else if (isImgUrlValid) return imgUrl;
		else return placeholderImg;
	};

	return (
		<Masonry.Item isSliderItem={isSliderItem} height={adjustHeight(index)}>
			<Masonry.Wrapper isSliderItem={isSliderItem}>
				<ImageOnLoadRPC>
					{({ setError }) => (
						<Masonry.Img onError={setError} src={setImgUrl()} alt={title} />
					)}
				</ImageOnLoadRPC>
			</Masonry.Wrapper>
			<Masonry.Mask
				isSliderItem={isSliderItem}
				live
				onClick={handlePlaySelected}
			>
				<Masonry.Content isSliderItem={isSliderItem} live>
					<Masonry.Info>
						{/* block channel name and info and replace them with placeholder for parental control */}
						{isContentBlocked ? (
							<Paragraph.Ellipsis>{placeholderRatingText}</Paragraph.Ellipsis>
						) : (
							<>
								<Paragraph.Ellipsis>
									{channelName} | {convertTime(since, till)}
								</Paragraph.Ellipsis>
								<Paragraph.Ellipsis>{title}</Paragraph.Ellipsis>
							</>
						)}
						{!noPrograms && (
							<Progress.Bar modifiers="easeGray">
								<Progress.Current modifiers="white" progress={progress}>
									&nbsp;
								</Progress.Current>
							</Progress.Bar>
						)}
					</Masonry.Info>
					{/* disable program description for parental control */}
					{!isContentBlocked && (
						<Masonry.DescriptionBox>
							<Masonry.Description>{description_short}</Masonry.Description>
						</Masonry.DescriptionBox>
					)}
				</Masonry.Content>
			</Masonry.Mask>
		</Masonry.Item>
	);
};

MasonryLive.propTypes = {
	element: PropTypes.shape({
		title: PropTypes.string.isRequired,
		epgProgrammes: PropTypes.array.isRequired,
		currentLiveIndex: PropTypes.number.isRequired
	}).isRequired,
	isSliderItem: PropTypes.bool,
	index: PropTypes.number.isRequired,
	adjustHeight: PropTypes.func.isRequired,
	channelID: PropTypes.string.isRequired,
	fetchPlayerConfig: PropTypes.func.isRequired,
	deleteVideoSession: PropTypes.func.isRequired,
	setSelectedProgram: PropTypes.func.isRequired,
	setTvProgramsLive: PropTypes.func.isRequired,
	videoSessionId: PropTypes.string,
	playerOnCancel: PropTypes.func.isRequired
};

const mapStateToProps = ({ player: { videoSessionId } }) => ({
	videoSessionId
});

export default connect(
	mapStateToProps,
	{
		fetchPlayerConfig,
		deleteVideoSession,
		setSelectedProgram,
		setTvProgramsLive,
		playerOnCancel
	}
)(MasonryLive);
