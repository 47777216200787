import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateWatchingInProgress,
	updateVideoSession,
	setPlayerReady
} from 'store/actions';
import throttle from 'lodash/throttle';

// import shaka-player
import shaka from 'shaka-player/dist/shaka-player.ui.js';

// Import helpers
import {
	INITIAL_STATE_PLAYER_VOD,
	TIME_THROTTLE,
	calculateVideoIntervalTime,
	getUserConfigVolume,
	setUserConfigVolume,
	TIME_THROTTLE_UPDATE_SEEK_TIME
} from '../helpers';
import { playerDeleteSyncRequest, SHAKA_PLAYER_ERRORS } from 'helpers/player';

// styles and helpers
import {
	VideoContainer,
	ShakaVideo,
	fetchPlaylistData,
	renderSubtitlesForSafari,
	removeShakaPlayerListeners,
	playerInitialization,
	setSupportError,
	setTrackingEvent,
	sendPlayerEvents,
	sendPreviousSessionTracking
} from '../shakaPlayer';

// Variables
import { TRACKING_EVENTS } from 'helpers/variables';

// Create context
export const ShakaPlayerContext = createContext();

const { SHAKA_PLAYER_ERROR_MESSAGE } = SHAKA_PLAYER_ERRORS;

class ShakaPlayerProvider extends React.Component {
	_isMounted = false;
	isPlayerReady = false;
	intervalUpdateWatchingProgress = null;
	intervalVideoSession = null;
	trackingInterval = null;
	streamStartDate = null;

	constructor() {
		super();
		this.state = INITIAL_STATE_PLAYER_VOD;
		this.timeThrottle = throttle(
			(time) => this.handleSetCurrentTime(time),
			TIME_THROTTLE
		);

		//Creating reference to store video component and video container
		this.videoComponentRef = React.createRef();
		this.videoContainerRef = React.createRef();

		// Shaka media
		this.video = null;
		this.videoContainer = null;
		this.player = null;
	}

	async componentDidMount() {
		const {
			playlist,
			isError,
			videoSessionId,
			isSiedebarVisible,
			configuration: { movieEndMargin }
		} = this.props;

		this._isMounted = true;
		this.setState({
			isSiedebarVisible,
			movieEndMargin
		});

		// send previous session tracking data
		sendPreviousSessionTracking();

		// Delete video sesion
		window.addEventListener('beforeunload', this.beforeunload, false);

		const playlistData = await fetchPlaylistData(playlist);

		if (videoSessionId && this._isMounted && !this.isPlayerReady && !isError) {
			// Install built-in polyfills to patch browser incompatibilities.
			shaka.polyfill.installAll();

			//Getting reference to video and video container on DOM
			this.video = this.videoComponentRef.current;
			this.videoContainer = this.videoContainerRef.current;

			if (shaka.Player.isBrowserSupported()) {
				playerInitialization.call(this, [shaka, playlistData]);
			} else {
				setSupportError.call(this);
			}
		} else if (isError && videoSessionId) {
			// If there is an error and video session is not equal to null
			// Turn on buffering
			this._isMounted && this.setState({ buffering: false });
		} else if (isError && !videoSessionId) {
			// If there is an error and video session is equal to null
			// Stop player
			this.player && this.player.destroy();
			// Turn on buffering,Set player ready
			this._isMounted && this.setState({ buffering: false, isReady: false });
		}
	}

	componentWillUnmount() {
		// Update watching progress time
		this.handleFetchWatchingProgress();

		this._isMounted = false;

		// Clear intervals
		clearInterval(this.intervalVideoSession);
		clearInterval(this.intervalUpdateWatchingProgress);
		clearInterval(this.trackingInterval);

		const { isError, videoSessionId, type, deleteVideoSession } = this.props;
		// Check if player is not undefined / null, if player container exists, if is not any API errors

		if (videoSessionId) {
			// Dispatch an deleteVideoSession action in player folder
			deleteVideoSession({ type });
		}

		if (!isError && this.player && this.isPlayerReady) {
			// send tracking events
			setTrackingEvent.call(this, TRACKING_EVENTS.CLOSED);
			sendPlayerEvents.call(this);
			// remove event listeners
			removeShakaPlayerListeners.call(this);
			// Destroy the player
			this.player.destroy();
			this.isPlayerReady = false;
		}
	}

	handleFetchWatchingProgress = (currentProgress = null) => {
		const { isPreview, isLiveEvent } = this.props;
		const { isReady } = this.state;
		const isAvaialbe = this._isMounted && isReady && this.video?.currentTime;

		if (isAvaialbe && !isPreview && !isLiveEvent) {
			const currentTime = currentProgress || this.video.currentTime;
			const time = Math.floor(currentTime);
			this.props.updateWatchingInProgress({
				productUuid: this.props.productID,
				time
			});
		}
	};

	beforeunload = () => {
		const { videoSessionId } = this.props;
		// delete session
		playerDeleteSyncRequest(videoSessionId);
	};

	setFullScreen = () => {
		if (!document.fullscreenElement) {
			this.videoContainer.requestFullscreen();
			this.setState({ isFullScreen: true });
		} else {
			document.exitFullscreen();
			this.setState({ isFullScreen: false });
		}
	};

	play = () => {
		this.video.play();
		this.setState({ isPaused: false });
	};

	pause = () => {
		this.video.pause();
		this.setState({ isPaused: true });
	};

	updateTime = (time) => {
		this.video.currentTime = time;
		setTrackingEvent.call(this, TRACKING_EVENTS.SEEKING);
	};

	updateSeekTime = throttle((next = true) => {
		const { seekSec } = this.state;
		const currentTime = this.video.currentTime;

		next
			? this.updateTime(currentTime + seekSec)
			: this.updateTime(currentTime - seekSec);
	}, TIME_THROTTLE_UPDATE_SEEK_TIME);

	setLanguage = (lang) => {
		const { profiles, selectedProfileBandwidth } = this.state;
		this.setState({ selectedLanguage: lang });
		this.player.selectAudioLanguage(lang);

		// set quality profile after audio language change
		const videoProfile = profiles[lang].find(
			({ bandwidth }) => bandwidth === selectedProfileBandwidth
		);

		if (videoProfile) {
			const { id, bandwidth } = videoProfile;
			this.setProfile({ id, bandwidth, lang });
		}
	};

	// Set subtitle
	setSubtitle = (value) => {
		if (value === 'disabled') {
			this.player.setTextTrackVisibility(false);
			this.setState({ selectedSubtitle: value });
		} else {
			this.player.setTextTrackVisibility(true);
			this.player.selectTextLanguage(value);
			this.setState({ selectedSubtitle: value });
		}
	};

	// Set profile
	setProfile = ({ id, bandwidth, lang }) => {
		const { profiles, selectedLanguage } = this.state;
		const clearBuffer = true;
		let language = selectedLanguage;

		// after change language 'selectedLanguage' has an old value
		// if 'lang' param exist set its value to language variable
		if (lang) {
			language = lang;
		}

		if (id !== 'auto') {
			this.player.configure({ abr: { enabled: false } });
			const variant = profiles[language].find((variant) => variant.id === id);
			this.player.selectVariantTrack(variant, clearBuffer);
		} else {
			this.player.configure({ abr: { enabled: true } });
		}

		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

		this.setState({
			selectedProfile: id,
			selectedProfileBandwidth: bandwidth
		});
	};

	// Mute or unmute player
	mute = () => {
		const { isMuted, volume } = this.state;
		const isVideoMuted = parseInt(volume) ? !isMuted : true;
		const options = { isMuted: isVideoMuted };
		this.setState(options);
		setUserConfigVolume(options);
	};

	// Set current volume
	setVolume = (volume) => {
		this.video.volume = volume / 100;
		const isMuted = parseInt(volume) === 0;
		const options = { volume, isMuted };
		this.setState(options);
		setUserConfigVolume(options);
	};

	onReady = () => {
		const {
			type,
			setPlayerReady,
			configuration: { videoSession }
		} = this.props;

		// Set player ready
		this.isPlayerReady = true;
		this.setState({ isReady: true });
		// Dispatch an setPlayerReady action in player folder
		setPlayerReady({ type });

		// Set watching in progress interval
		const watchingIntervalTime = parseInt(
			process.env.REACT_APP_WATCHING_IN_PROGRESS
		);
		this.intervalUpdateWatchingProgress = setInterval(
			this.handleFetchWatchingProgress,
			watchingIntervalTime
		);
		// Set video session interval
		const videoSessionInvervalTime = calculateVideoIntervalTime(videoSession);
		this.intervalVideoSession = setInterval(
			this.handleUpdateVideoSession,
			videoSessionInvervalTime
		);
		// Set player volume
		const { volume } = getUserConfigVolume();
		this.setVolume(volume);
	};

	handleUpdateVideoSession = () => {
		const { videoSessionId, type, productID, updateVideoSession } = this.props;

		updateVideoSession({ videoSessionId, productID, type }, () => {
			// When is an api error
			// Destroy the player
			this.player.destroy();
			// Turn on buffering
			this.setState({ buffering: false, isReady: false });
			// Clear intervals
			clearInterval(this.intervalVideoSession);
			clearInterval(this.intervalUpdateWatchingProgress);
		});
	};

	onPlay = () => {
		this.setState({ isPaused: false });
		setTrackingEvent.call(this, TRACKING_EVENTS.PLAYING);
	};

	onPause = () => {
		this.setState({ isPaused: true });
		setTrackingEvent.call(this, TRACKING_EVENTS.PAUSED);
	};

	onBufferStateChange = () => {
		const buffering = this.player.isBuffering();
		this.setState({ buffering });
		buffering && setTrackingEvent.call(this, TRACKING_EVENTS.BUFFERING);
	};

	onEnded = () => {
		this.setState({ isEnded: true, isPaused: true });
		setTrackingEvent.call(this, TRACKING_EVENTS.COMPLETE);
	};

	onAutoQualityChange = () =>
		setTrackingEvent.call(this, TRACKING_EVENTS.TRACK_CHANGED);

	retryPlaying = () => {
		this.player.retryStreaming();
	};

	onErrorEvent = (event) => {
		// Extract the shaka.util.Error object from the event.
		this.onError(event.detail);
	};

	onError = (error) => {
		const isOnline = navigator.onLine;

		if (!isOnline) return;

		if (this && this.props) {
			const { setPlayerPlaybackError, type } = this.props;

			setTrackingEvent.call(this, TRACKING_EVENTS.ERROR);

			// Catch only known errors which should be handled in UI
			if (error && error.code && isOnline) {
				this.setState({ isReady: false, buffering: false });
				// Destroy the player
				if (this.player) {
					this.player.destroy();
				}
				// Set error message
				error.message = SHAKA_PLAYER_ERROR_MESSAGE;
				// Dispatch an setPlayerPlaybackError action in player folder
				setPlayerPlaybackError(error, type);
				// Set player ready / Turn on buffering
				// Clear intervals
				clearInterval(this.intervalVideoSession);
				clearInterval(this.intervalUpdateWatchingProgress);
			}
		}
	};

	onFullScreenChange = () => {
		const isFullScreen = !!document.fullscreenElement;
		this.setState({ isFullScreen });
	};

	getDuration = () => {
		const { isReady, duration } = this.state;

		const isAvailable = this._isMounted && isReady && this.video && duration;
		return isAvailable ? this.video.duration : null;
	};

	handleSetCurrentTime = (time) => {
		const { isReady } = this.state;
		const isAvaialbe = this._isMounted && this.video && isReady;
		// Get duration
		if (isAvaialbe) {
			const duration = this.getDuration();
			if (duration && this.state.duration !== duration) {
				this.setState({ duration });
			}

			// Set end margin action
			const { endMarginAction, movieEndMargin } = this.state;
			const endMargin = duration - movieEndMargin;

			if (duration && time > endMargin && !endMarginAction) {
				this.setState({ endMarginAction: true });
			} else if (duration && time < endMargin && endMarginAction) {
				this.setState({ endMarginAction: false });
			}
		}
	};

	currentTimeProgress = (callback) =>
		this.video.addEventListener('timeupdate', () =>
			callback(this.video.currentTime)
		);

	restart = () => {
		this.video.currentTime = 0;
		this.setState({ isEnded: false });
	};

	onTimeUpdate = () => this.timeThrottle(this.video.currentTime);

	setEndMargin = (value = true) => {
		this.setState({ endMarginAction: value, endMarginActionCancel: value });
	};

	render() {
		const {
			closePreview,
			isPreview,
			isSafari,
			isFocus,
			children,
			resetFocus,
			productID,
			title,
			configuration: { videoId },
			floatMode,
			isIphone,
			autoplay,
			isLiveEvent
		} = this.props;

		const { isReady, isMuted } = this.state;

		const currentTime = this.video ? this.video.currentTime : 0;

		return (
			<ShakaPlayerContext.Provider
				value={{
					...this.state,
					isPreview,
					isSafari,
					closePreview,
					setVolume: this.setVolume,
					setSubtitle: this.setSubtitle,
					setLanguage: this.setLanguage,
					setProfile: this.setProfile,
					mute: this.mute,
					currentTime,
					updateTime: this.updateTime,
					updateSeekTime: this.updateSeekTime,
					setFullScreen: this.setFullScreen,
					play: this.play,
					pause: this.pause,
					restart: this.restart,
					currentTimeProgress: this.currentTimeProgress,
					setEndMargin: this.setEndMargin,
					resetFocus,
					productID,
					videoId,
					video: this.video,
					floatMode,
					isLiveEvent
				}}
			>
				<VideoContainer
					className="video-container"
					ref={this.videoContainerRef}
					isReady={isReady}
					isFocus={isFocus}
					isIphone={isIphone}
				>
					<ShakaVideo
						className="shaka-video"
						id="shaka-video"
						ref={this.videoComponentRef}
						muted={isMuted}
						alt={title}
						autoPlay={autoplay}
						crossOrigin="true"
					>
						{isSafari && renderSubtitlesForSafari.call(this)}
					</ShakaVideo>
					{children}
				</VideoContainer>
			</ShakaPlayerContext.Provider>
		);
	}
}

ShakaPlayerProvider.defaultProps = {
	isSiedebarVisible: false,
	isSafari: false,
	floatMode: { isPlayerOpen: false, isFloatMode: false, isPageLoaded: false },
	autoplay: true,
	isIphone: false
};

ShakaPlayerProvider.propTypes = {
	isSiedebarVisible: PropTypes.bool,
	playlist: PropTypes.string.isRequired,
	isError: PropTypes.bool.isRequired,
	videoSessionId: PropTypes.string,
	deleteVideoSession: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	productID: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	configuration: PropTypes.object.isRequired,
	closePreview: PropTypes.func.isRequired,
	isPreview: PropTypes.bool.isRequired,
	isSafari: PropTypes.bool.isRequired,
	isFocus: PropTypes.bool.isRequired,
	resetFocus: PropTypes.func.isRequired,
	autoplay: PropTypes.bool.isRequired,
	updateWatchingInProgress: PropTypes.func.isRequired,
	setPlayerReady: PropTypes.func.isRequired,
	updateVideoSession: PropTypes.func.isRequired,
	setPlayerPlaybackError: PropTypes.func.isRequired,
	continueWatchingTime: PropTypes.number,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
	floatMode: PropTypes.object,
	isIphone: PropTypes.bool.isRequired,
	productType: PropTypes.string.isRequired,
	isLiveEvent: PropTypes.bool.isRequired
};

const mapStateToProps = (state, { type }) => {
	const {
		movieDetails: { data },
		continue_watching
	} = state;

	const {
		playlist,
		configuration,
		error: { isError },
		videoSessionId
	} = state[type];

	const continueWatchingTime = continue_watching?.continueProgress?.time;

	return {
		playlist,
		productID: data?.uuid,
		title: data?.metadata?.title,
		isError,
		configuration,
		videoSessionId,
		continueWatchingTime,
		productType: data?.type
	};
};

export default connect(mapStateToProps, {
	deleteVideoSession,
	setPlayerPlaybackError,
	updateWatchingInProgress,
	updateVideoSession,
	setPlayerReady
})(ShakaPlayerProvider);
