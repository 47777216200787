import styled, { css } from 'styled-components';

// Import styles
import {
	flex,
	size,
	productBackgroundColor,
	customMinResponsive,
	customResponsive
} from 'components/styles';

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	${productBackgroundColor}
`;

export const Figure = styled.figure`
	${flex()}
	flex-direction: column;
	width: ${({ width }) => width}px;
	height: ${({ height }) => height}px;
	border-radius: ${({ theme }) =>
		theme.product.coverBackground.galleryItemRoundSize}rem;

	${({ isFullscreen }) =>
		isFullscreen &&
		css`
			&,
			img {
				border-radius: 0;
			}
		`}
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	border-radius: 5px;
	cursor: ${({ cursor }) => cursor};
	filter: ${({ isContrast }) => (isContrast ? 'grayscale(1)' : 'grayscale(0)')};

	${({ isFullscreen }) =>
		isFullscreen &&
		customResponsive(
			size.medium,
			css`
				object-fit: contain;
			`
		)}
`;

export const Informations = styled.div`
	width: 100%;
	font-size: ${({ isFullscreen }) => (isFullscreen ? '1.6rem' : '1.2rem')};
	font-weight: ${({ theme }) => theme.font.normal};
	color: ${({ theme }) => theme.product.coverBackground.galleryDescription};

	${productBackgroundColor}

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ isFullscreen }) => (isFullscreen ? '2.4rem' : '1.8rem')};
		`
	)}
		${({ isFullscreen }) =>
		isFullscreen &&
		css`
			padding: 0 2rem;
		`};
`;

export const Content = styled.div`
	${flex({ jc: 'space-between', ai: 'flex-start' })}
	flex-wrap: wrap;
`;

export const InfoBox = styled.div`
	${flex()}
`;

export const Title = styled.p`
	font-size: ${({ theme }) =>
		theme.fontSize.productDetails.gallery.title.mobile}rem;
	text-align: right;
	line-height: 1.6rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.productDetails.gallery.title.desktop}rem;
			line-height: 3.6rem;
		`
	)}
`;

export const AssetNumber = styled.span`
	font-size: ${({ theme }) =>
		theme.fontSize.productDetails.gallery.assetNumber.mobile}rem;

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.productDetails.gallery.assetNumber.desktop}rem;
		`
	)}
`;

export const Author = styled.p`
	opacity: ${({ isLightBackground }) => (isLightBackground ? 0.5 : 1)};
	font-style: italic;
	font-size: ${({ theme, isFullscreen }) =>
		isFullscreen
			? theme.fontSize.xs
			: theme.fontSize.productDetails.gallery.author.mobile}rem;
	line-height: 1.4rem;
	${flex()}

	& > span {
		padding: 0.2rem 0;
	}

	& > span:nth-child(1) {
		text-align: right;
	}

	& > span:nth-child(3) {
		text-align: left;
	}

	${({ isFullscreen, theme }) =>
		isFullscreen &&
		css`
			font-weight: ${theme.font.medium};
			height: ${({ isFullscreen }) =>
				isFullscreen ? `${theme.fontSize.md}rem` : 'unset'};
		`};

	${customMinResponsive(
		size.medium,
		css`
			font-size: ${({ theme }) =>
				theme.fontSize.productDetails.gallery.author.desktop}rem;
			line-height: 2rem;
			height: ${({ theme, isFullscreen }) =>
				isFullscreen ? `${theme.fontSize.lg}rem` : 'unset'};
			margin-top: 0;

			& > span {
				padding: 0.4rem 0;
			}
		`
	)}
`;

export const Divider = styled.span`
	margin: 0 4px;
`;

export const FullscreenWrapper = styled.section`
	height: 100vh;
	width: 100vw;
	padding: 2.8rem 1.6rem;

	${customMinResponsive(
		size.medium,
		css`
			padding: 6.7rem 16.3rem;
		`
	)};
`;
