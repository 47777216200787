import styled, { css } from 'styled-components';

// Import utilities
import { customMinResponsive, size } from 'components/styles';

export const Container = styled.div`
	order: 1;
	color: ${({ theme }) => theme.body.footer.color};
	font-size: ${({ theme }) => theme.fontSize.footer.media.text.mobile}rem;
	font-weight: ${({ theme }) => theme.font.light};
	line-height: 1.6rem;
	text-align: center;
	width: 100%;

	a {
		text-decoration: none;
		color: ${({ theme }) => theme.white};

		&:hover {
			color: ${({ theme: { body } }) => body.footer.hover};
		}

		&:focus-visible {
			color: ${({ theme: { body } }) => body.footer.hover};
		}
	}

	${customMinResponsive(
		size.medium,
		css`
			order: 0;
			text-align: right;
			font-weight: ${({ theme }) => theme.font.light};
			font-size: ${({ theme }) => theme.fontSize.footer.media.text.desktop}rem;
			line-height: 2.3rem;
		`
	)};
`;
