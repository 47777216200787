import { useContext } from 'react';

// Import context
import {
	ShakaPlayerContext,
	TheoPlayerContext
} from 'components/context/player/player_vod';

// Import variables
import { DEFAULT_PLAYER, THEO_PLAYER } from 'helpers/variables';

const usePlayerVodContext = () => {
	const shakaContext = useContext(ShakaPlayerContext);
	const theoContext = useContext(TheoPlayerContext);

	switch (DEFAULT_PLAYER) {
		case THEO_PLAYER:
			return theoContext;

		default:
			return shakaContext;
	}
};

export default usePlayerVodContext;
