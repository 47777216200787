import styled, { css } from 'styled-components';
import { MenuButton } from '@reach/menu-button';

// Import components
import { Nav } from 'components/elements';
import { customMinResponsive, size } from 'components/styles';

export const NavButton = styled(MenuButton)`
	text-transform: capitalize;
	white-space: nowrap;
	background: none;
	border: none;
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 3rem;
	color: ${({ theme: { body } }) => body.nav.color};
	font-size: ${({ theme }) => theme.fontSize.nav.link}rem;
	font-weight: ${({ theme }) => theme.font.medium};
	transition: all 0.3s ease-in-out;

	${customMinResponsive(
		size.medium,
		css`
			padding: 0 1.25rem !important;
		`
	)}

	${customMinResponsive(
		950,
		css`
			padding: 0 2rem !important;
		`
	)}

	${customMinResponsive(
		1050,
		css`
			padding: 0 3rem !important;
		`
	)}

	&:hover {
		color: ${({ theme }) => theme.body.nav.hover};
	}

	&:focus-visible {
		color: ${({ theme }) => theme.body.nav.hover};
	}

	&:focus {
		outline: none;
	}
`;

export const NavLinksListStyled = styled(Nav.List)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	${customMinResponsive(
		size.medium,
		css`
			transform: ${({ isAuth }) =>
				isAuth ? 'translate(-60%, -50%);' : 'translate(-65%, -50%);'};
		`
	)}

	${customMinResponsive(
		1050,
		css`
			transform: ${({ isAuth }) =>
				isAuth ? 'translate(-58%, -50%);' : 'translate(-62%, -50%);'};
		`
	)}

	${customMinResponsive(
		size.large,
		css`
			transform: translate(-50%, -50%);
		`
	)}
`;
