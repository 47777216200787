import * as types from './types';

export const setPlayerOpen = (payload) => (dispatch) => {
	dispatch({ type: types.SET_PLAYER_OPEN, payload });
};

export const setFloatMode = (payload) => (dispatch) => {
	dispatch({ type: types.SET_FLOAT_MODE, payload });
};

export const setPageLoaded = (payload) => (dispatch) => {
	dispatch({ type: types.SET_PAGE_LOADED, payload });
};

export const setInitPageMode = (payload) => (dispatch) => {
	dispatch({ type: types.SET_INIT_PAGE_MODE, payload });
};

export const setCoordinates = (payload) => (dispatch) => {
	dispatch({ type: types.SET_COORDINATES, payload });
};
