import styled, { css } from 'styled-components';

// Import utilties
import { customMinResponsive, flex, size } from 'components/styles';

export const Stores = styled.div`
	${flex()}
	column-gap: 1.5rem;
	margin: 2.2rem auto;

	${customMinResponsive(
		size.medium,
		css`
			margin: 2.2rem 0;
		`
	)}
`;

export const AnchorStyled = styled.a`
	${flex()}
`;
