// Import helpers
import { PAGE_META_TAGS } from 'helpers/meta_tags';

// Import components
import {
	Static,
	ResetPassword,
	Contact,
	EmailConfirmation,
	CookiesManagement
} from './components';

// Import paths
import { APP_CORE_PATHS, DOCUMENTS_PATHS } from 'components/routes/paths';

const { PASSWORD_RESET, EMAIL_CONFIRMATION, CONTACT, COOKIES } = APP_CORE_PATHS;

const { DOCUMENT } = DOCUMENTS_PATHS;

const setPath = (path) => `/${path}`;
export const appRoutes = [
	{
		path: setPath(CONTACT),
		component: Contact,
		metaTags: PAGE_META_TAGS.CONTACT
	},
	{
		path: DOCUMENT,
		component: Static
	},
	{ path: setPath(PASSWORD_RESET), component: ResetPassword },
	{ path: setPath(EMAIL_CONFIRMATION), component: EmailConfirmation },
	{ path: setPath(COOKIES), component: CookiesManagement }
];
