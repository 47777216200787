import React from 'react';
import { useTranslation } from 'react-i18next';

// Import components
import { HeaderIcon } from 'components/elements';

// Import logic
import usePortalLogo from './usePortalLogo';

// Import helpers
import { WATERMARK_URL } from 'helpers/variables';

const PortalLogo = () => {
	const { t } = useTranslation();

	const { clearInstitution } = usePortalLogo();

	return (
		<HeaderIcon
			onClick={clearInstitution}
			ariaLabel={t('aria_header_logo_link')}
			title={t('aria_header_logo_link')}
			src={WATERMARK_URL}
			alt={t('common_portal_logo_title', {
				app_owner: process.env.REACT_APP_PROJECT
			})}
		/>
	);
};

export default PortalLogo;
