import React from 'react';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';

// Import styles
import { Container } from './styles';

// Import variables
import { EXTERNAL_LINKS } from 'helpers/variables';

const { BLUEONLINE } = EXTERNAL_LINKS();

const Copyrights = () => {
	const { t } = useTranslation();

	const currentYear = getYear(new Date());
	return (
		<Container>
			<p>
				{currentYear}{' '}
				{t('common_footer_copyrights', {
					app_owner: process.env.REACT_APP_PROJECT
				})}{' '}
				{t('common_footer_system_origin')}{' '}
				<a
					href={BLUEONLINE}
					target="_blank"
					rel="noopener noreferrer"
					aria-label={t('aria_footer_nav_blueonline')}
					title={t('aria_footer_nav_blueonline')}
				>
					{t('common_footer_system_origin_blueonline')}
				</a>
			</p>
		</Container>
	);
};

export default Copyrights;
