import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

// Import styles
import { customMinResponsive, size, flex } from 'components/styles';

export const SearchBoxStyled = styled.div`
	position: absolute;
	display: block;
	min-width: 100%;
	max-width: 100vw;
	min-height: 100vh;
	top: 7rem;
	left: 0;
	background-color: ${({ theme: { search } }) => search.dropdown.bg};
	box-shadow: 0 0 20px ${({ theme: { search } }) => search.dropdown.shadow};
	z-index: 9999;

	${customMinResponsive(
		size.medium,
		css`
			position: absolute;
			top: 4.2rem;
			left: 5.8rem;
			width: 50rem;
			min-width: auto;
			min-height: auto;
		`
	)}
`;

export const Top = styled.div`
	padding: 2.2rem 2rem 2.2rem 0;
	${flex({ jc: 'flex-end' })}

	${customMinResponsive(
		size.medium,
		css`
			padding: 2.2rem 3rem 2.2rem 0;
		`
	)}
`;

export const LinkStyled = styled(Link)`
	&:link,
	&:visited {
		font-size: ${({ theme }) => theme.fontSize.search.searchBox.showAllLink}rem;
		color: ${({ theme }) => theme.search.dropdown.showAllLink};
		font-weight: ${({ theme }) => theme.font.semiBold};
		transition: all 0.2s ease-in-out;
	}
	&:hover,
	&:active {
		color: ${({ theme }) => theme.search.dropdown.showAllLinkHover};
	}
`;

export const SearchList = styled.ul.attrs(({ isLoaded }) => ({
	style: {
		minHeight: isLoaded ? '10rem' : '20rem'
	}
}))`
	list-style: none;
`;
