import * as types from 'store/actions/types';
import services from 'services/services';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';
import { setChannelsByLocal } from 'store/actions';

// Import helpers
import {
	LOCALSTORAGE_USER,
	COOKIE_UID,
	GTM_TRACKING_EVENTS
} from 'helpers/variables';
import { checkUserResources, checkUserPackets } from './auth_helpers';
import { getUserAgentInfo, SUBSCRIBER_ANONIMIZE_API } from 'helpers';
import { getErrorCodeResults } from 'helpers/error_code_api';
import { getAffiliateClientQuery } from 'helpers/affiliate_client';

//Import actions
import { clearMovieDetails } from 'store/actions';

const { REGISTRATION_SUCCESSFUL } = GTM_TRACKING_EVENTS;

// ******************** AUTHORIZE USER ********************
export const authorizeUser = (values) => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.AUTH_USER_LOADING,
			payload: true
		});

		let url = `/subscriber/login`;

		if (values.idToken) {
			// Get AffiliateClient ID query
			const query = getAffiliateClientQuery();

			url = `/subscriber/login/firebase${query}`;
		}

		// Get uid
		const uid = Cookies.get(COOKIE_UID);

		// Get user agent
		const { browser, os } = getUserAgentInfo();

		// Create options
		const options = {
			os: os.name,
			osVersion: os.version,
			maker: 'unknown',
			agent: browser.name,
			...values,
			uid
		};

		// Get login response
		const { data: user } = await services.post(url, options);

		// Check user if device exist, all agreements are accepted and if there is an device limit
		// Create user data
		const userData = checkUserResources(user);
		const userLocalStorageData = checkUserResources(user, true);

		// Set user data to local storage
		localStorage.setItem(
			LOCALSTORAGE_USER,
			JSON.stringify(userLocalStorageData)
		);

		// Clear movie details -> important for parental control proper behaviour after user login
		clearMovieDetails(dispatch);

		if (user.need_complete_registration) {
			const userDataWithDetails = {
				...user,
				userDetails: values.userDetails
			};
			// Dispatch an action - required to complete registration process
			dispatch({
				type: types.AUTH_USER_REGISTER_COMPLETION_REQUIRED,
				payload: userDataWithDetails
			});
		} else {
			// Dispatch an action - user is authenticated
			dispatch({
				type: types.AUTH_USER_SUCCESS,
				payload: userData
			});
		}
	} catch (error) {
		if (error.response) {
			if (error.response.status === 409) {
				const user = error.response.data;
				// Create user data
				const userData = checkUserResources(user);

				// Set user data to local storage
				localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify(userData));

				// Dispatch an action
				dispatch({
					type: types.AUTH_USER_SUCCESS,
					payload: userData
				});
			} else {
				const { codeErrorMessage } = getErrorCodeResults(error);
				// Dispatch an error action
				error.response.data.message = codeErrorMessage;
				dispatch({
					type: types.AUTH_USER_ERROR,
					payload: error.response
				});
			}
		} else {
			// Dispatch an error action
			dispatch({
				type: types.AUTH_USER_ERROR,
				payload: error.response
			});
		}
	}
};

// ******************** UPDATE USER AGREEMENTS ********************
export const updateUserAgreements = (missingAgreements) => async (dispatch) => {
	try {
		const agreementIds = missingAgreements.map(({ id }) => id);
		const options = { agreement_version_ids: agreementIds };
		const url = `/subscriber/agreements/accept`;
		const isAgreementsExist = agreementIds.length;

		// IF AGREEMENTS ARE EMPTY, WE AVOID THE APPROVAL PROCESS
		isAgreementsExist && (await services.post(url, options));

		// Get user from local storage
		const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
		// Set updated user data to local storage with accepted agreements
		localStorage.setItem(
			LOCALSTORAGE_USER,
			JSON.stringify({
				...user,
				status: { ...user.status, missingAgreements },
				isLogged: true,
				accepetedAgreements: true
			})
		);

		// Dispatch an action
		dispatch({
			type: types.AUTH_UPDATE_AGREEMENTS_SUCCESS,
			payload: user
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.AUTH_UPDATE_AGREEMENTS_ERROR,
			payload: error.response.data
		});
	}
};

// ******************** FETCH USER'S PACKETS ********************
export const fetchUserPackets = () => async (dispatch) => {
	try {
		// Get user's packets
		const { data: packets } = await services.get(`/products/packet`);
		const { data: products } = await services.get(`/subscriber/products/uuids`);

		const userPackets = checkUserPackets(packets.data, products.data);

		// Dispatch an action
		dispatch({
			type: types.FETCH_USER_PACKETS_SUCCESS,
			payload: { packets: userPackets, products }
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.FETCH_USER_PACKETS_ERROR,
			payload: error.response.status
		});
	}
};

// ******************** FETCH USER'S DETAILS ********************
export const fetchUserDetails = () => async (dispatch) => {
	try {
		dispatch({
			type: types.AUTH_USER_DETAILS_LOADING,
			payload: true
		});

		// Get user from local storage
		const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));

		// Get login response
		const url = `/subscriber/details`;
		const { data } = await services.get(url);

		// Create user data
		// Check if hide unavaiable channels
		const hideUnavailable = data.hide_unavailable_products === 1 ? true : false;
		const parentalControlEnabled = data.parental_control_rating ? true : false;
		const userData = {
			...user,
			...data,
			isLogged: true,
			deviceExists: true,
			accepetedAgreements: true,
			parentalControlEnabled,
			hideUnavailable
		};

		// Dispatch an action
		dispatch({
			type: types.AUTH_USER_DETAILS_SUCCESS,
			payload: userData
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.AUTH_USER_DETAILS_ERROR,
			payload: error.response
		});
	}
};

// ******************** FETCH USER'S DEVICES ********************
export const fetchUserDevices = () => async (dispatch) => {
	try {
		dispatch({
			type: types.AUTH_FETCH_USER_DEVICES_LOADING,
			payload: true
		});

		// Get user's devices
		const url = `/subscriber/devices/active?order[0][column]=9&order[0][dir]=desc`;
		const { data } = await services.get(url);

		// Dispatch an action
		dispatch({
			type: types.AUTH_FETCH_USER_DEVICES_SUCCESS,
			payload: data.data
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.AUTH_FETCH_USER_DEVICES_ERROR,
			payload: error.response.data
		});
	}
};

// ******************** UPDATE USER'S DEVICE ********************
export const updateUserDevice = (name, uid) => async (dispatch) => {
	try {
		dispatch({
			type: types.AUTH_UPDATE_USER_DEVICES_LOADING,
			payload: true
		});

		// Get user devices
		const url = `/subscriber/device/toggle`;
		const {
			data: { token }
		} = await services.put(url, {
			uidOfDeviceToDelete: uid,
			nameOfNewDevice: name
		});

		// Get user from local storage
		const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
		// Set updated user data to local storage
		localStorage.setItem(
			LOCALSTORAGE_USER,
			JSON.stringify({ ...user, token, isLogged: user.accepetedAgreements })
		);

		// Dispatch an action
		dispatch({
			type: types.AUTH_UPDATE_USER_DEVICES_SUCCES,
			payload: true
		});
	} catch (error) {
		// Remove user data from local storage
		localStorage.removeItem(LOCALSTORAGE_USER);

		if (error.response) {
			const { codeErrorMessage } = getErrorCodeResults(error);
			// Dispatch an error action
			error.response.data.message = codeErrorMessage;
			dispatch({
				type: types.AUTH_UPDATE_USER_DEVICES_ERROR,
				payload: error.response
			});
		} else {
			// Dispatch an error action
			dispatch({
				type: types.AUTH_UPDATE_USER_DEVICES_ERROR,
				payload: error.response
			});
		}
	}
};
// ******************** UPDATE USER'S DEVICE ********************
export const addUserDevice = (name) => async (dispatch) => {
	try {
		// Get user devices
		const url = `/subscriber/device/name`;
		await services.post(url, { name });

		// Get user from local storage
		const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
		// Set updated user data to local storage
		localStorage.setItem(
			LOCALSTORAGE_USER,
			JSON.stringify({
				...user,
				deviceExists: true,
				isLogged: user.accepetedAgreements
			})
		);
		// Dispatch an action
		dispatch({
			type: types.AUTH_UPDATE_USER_DEVICES_SUCCES,
			payload: true
		});
	} catch (error) {
		// Remove user data from local storage
		localStorage.removeItem(LOCALSTORAGE_USER);
		// Dispatch an error action
		dispatch({
			type: types.AUTH_UPDATE_USER_DEVICES_ERROR,
			payload: error.response.data
		});
	}
};

// ******************** SET UNAVAILABLE CHANNEL ********************
export const setUnavailableChannels = (unavailable) => async (dispatch) => {
	try {
		// Sign out user from application
		const url = `/subscriber/hide/unavailable`;
		await services.put(url, { hide: unavailable });
		// Get user from local storage
		const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
		const updatedUser = { ...user, hideUnavailable: unavailable };
		// Set updated user data to local storage
		localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify({ ...updatedUser }));
		// Dispatch an action
		dispatch({
			type: types.SET_USER_UNAVAILABLE_CHANNELS_SUCCESS,
			payload: unavailable
		});
	} catch (error) {
		// Dispatch an error action
		dispatch({
			type: types.SET_USER_UNAVAILABLE_CHANNELS_ERROR,
			payload: true
		});
	}
};

// ******************** SET SUBSCRIBER IN LOCAL ********************
export const setSubscriberInLocal = () => async (dispatch, getState) => {
	try {
		// Sign out user from application
		const url = `/subscriber/inLocal`;
		// Get user location
		const {
			data: { value: isUserInLocal }
		} = await services.get(url);

		// Get user from local storage
		const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
		const updatedUser = { ...user, subscriber_local_active: isUserInLocal };
		// Set updated user data to local storage
		localStorage.setItem(LOCALSTORAGE_USER, JSON.stringify({ ...updatedUser }));

		isUserInLocal && setChannelsByLocal()(dispatch, getState);

		// Dispatch an action
		dispatch({
			type: types.SET_USER_IN_LOCAL,
			payload: isUserInLocal
		});
	} catch (error) {
		/* empty */
	}
};

// ******************** CLEAR AUTHORIZATION ********************
export const clearUserAuthorize = () => {
	// Remove user data from local storage
	localStorage.removeItem(LOCALSTORAGE_USER);
	// Dispatch an action
	return {
		type: types.AUTH_USER_CLEAR,
		payload: null
	};
};

// ******************** SIGN OUT ********************
export const signOut =
	(authenticate = true) =>
	async (dispatch) => {
		try {
			// Dispatch an loading action
			dispatch({
				type: types.SIGN_OUT_LOADING,
				payload: true
			});

			if (authenticate) {
				// Sign out user from application
				const url = `/subscriber/logout`;
				await services.post(url);
			}

			// Remove user data from local storage
			localStorage.removeItem(LOCALSTORAGE_USER);

			// Dispatch an action
			dispatch({
				type: types.SIGN_OUT,
				payload: true
			});
		} catch (error) {
			// Dispatch an error action
			dispatch({
				type: types.SIGN_OUT_ERROR,
				payload: true
			});
		}
	};

// ******************** COMPLETE USER REGISTRATION BY FIREBASE ********************
export const completeUserRegistration =
	({ userData, acceptedAgreementsVersions, areLoginAgreementsAccepted }) =>
	async (dispatch) => {
		try {
			//post user data
			const url = `/subscriber/profile/update/personalData`;
			await services.put(url, userData);

			// update agreeements
			const agreementsUrl = '/subscriber/agreements/accept';
			await services.post(agreementsUrl, {
				agreement_version_ids: acceptedAgreementsVersions
			});

			// Get user from local storage
			const user = JSON.parse(localStorage.getItem(LOCALSTORAGE_USER));
			// Set updated user data to local storage
			localStorage.setItem(
				LOCALSTORAGE_USER,
				JSON.stringify({
					...user,
					need_complete_registration: false
				})
			);

			TagManager.dataLayer({ dataLayer: { event: REGISTRATION_SUCCESSFUL } });

			dispatch({
				type: types.AUTH_USER_FIREBASE_REGISTER_SUCCESS,
				payload: { areLoginAgreementsAccepted }
			});
		} catch (error) {
			if (error.response) {
				const { codeErrorMessage } = getErrorCodeResults(error);
				// Dispatch an error action
				error.response.data.message = codeErrorMessage;
				dispatch({
					type: types.AUTH_USER_FIREBASE_REGISTER_ERROR,
					payload: error.response
				});
			} else {
				// Dispatch an error action
				dispatch({
					type: types.AUTH_USER_FIREBASE_REGISTER_ERROR,
					payload: error.response
				});
			}
		}
	};

// ******************** REMOVE ACCOUNT ********************
export const removeAccount = () => async (dispatch) => {
	try {
		dispatch({
			type: types.REMOVE_ACCOUNT_LOADING
		});

		await services.post(SUBSCRIBER_ANONIMIZE_API);

		dispatch({
			type: types.REMOVE_ACCOUNT_SUCCESS
		});
	} catch (error) {
		dispatch({
			type: types.REMOVE_ACCOUNT_ERROR,
			payload: true
		});
	}
};

export const removeUser = (toggle) => (dispatch) => {
	localStorage.removeItem(LOCALSTORAGE_USER);

	toggle();

	dispatch({
		type: types.SIGN_OUT,
		payload: true
	});
};

export const acceptFunctionalCookies =
	(status = true) =>
	(dispatch) => {
		dispatch({
			type: types.ACCEPTED_FUNCTIONAL_COOKIES,
			payload: status
		});
	};
