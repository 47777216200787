import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Import icons
import { ReactComponent as IconClock } from 'assets/icons/ic_clock.svg';

// Import styles
import { iconClock } from 'components/styles';

// Import components
import { Heading } from 'components/elements';

export const Content = styled.div`
	flex: 1;
	padding: 1.5rem;
`;

export const Top = styled.div`
	margin-bottom: 0.8rem;
`;

export const Clock = styled(IconClock)`
	position: relative;
	top: 0.1rem;
	${iconClock};

	g,
	path {
		fill: ${({ theme }) => theme.popover.icon};
	}
`;

export const Time = styled.span`
	color: ${({ theme }) => theme.popover.time};
	font-size: ${({ theme }) => theme.fontSize.popover.time}rem;
	font-weight: ${({ theme }) => theme.font.medium};
`;

export const Title = styled(Heading.Third)`
	color: ${({ theme }) => theme.popover.title};
	font-size: ${({ theme }) => theme.fontSize.popover.title}rem;
	font-weight: ${({ theme }) => theme.font.bold};
	margin-bottom: 0.4rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Genres = styled.span`
	font-size: ${({ theme }) => theme.fontSize.popover.genres}rem;
	color: ${({ theme }) => theme.popover.genres};
	font-weight: ${({ theme }) => theme.font.medium};
	margin-bottom: 0.4rem;
	margin-right: 1rem;
`;

export const Description = styled.p`
	color: ${({ theme }) => theme.popover.description};
	font-size: ${({ theme }) => theme.fontSize.popover.description}rem;
	font-weight: ${({ theme }) => theme.font.normal};
	margin: 1rem 0;
`;

export const LinkStyled = styled(Link)`
	&:link,
	&:visited {
		margin-left: 0.4rem;
		font-size: ${({ theme }) => theme.fontSize.popover.link}rem;
		font-weight: ${({ theme }) => theme.font.medium};
		color: ${({ theme }) => theme.popover.link};
		transition: all 0.3s ease-in-out;
	}
	&:hover,
	&:active {
		color: ${({ theme }) => theme.popover.linkHover};
	}
`;
