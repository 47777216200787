import UAParser from 'ua-parser-js';
import NoImageSVG from 'components/views/vod/images/placeholder.svg';
import getSlug from 'speakingurl';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Import variables
import {
	APP_PROJECT_TYPES,
	PLAYER_SELECTORS,
	ROUTES_CORE_PATHS,
	POSTAL_CODES_REGEX,
	SESSIONSTORAGE_INSTITUTION,
	PRODUCTS_TYPES
} from 'helpers/variables';

// Import helpers
import { PRODUCT_TYPES_MAPPED } from 'helpers';

// Import app core paths
import { APP_CORE_PATHS } from 'components/routes/paths';

// Import StyledBorderButton styles
import {
	backgroundHovered,
	borderHovered,
	smartBackgroundHovered,
	smartBorderHovered
} from 'components/styles';

const { HOME, PRODUCT } = APP_CORE_PATHS;
const {
	CHANNEL,
	VOD,
	SERIES,
	COLLECTION,
	ARTICLE,
	LIVE_EVENT,
	PASS,
	PACKET,
	VOUCHER_TEMPLATE,
	EPISODE,
	SEASON,
	PROVISION,
	CHANNEL_EPG,
	BANNER
} = PRODUCTS_TYPES;

export * from './countries';
export * from './products';
export * from './api';
export * from './promotions';
export * from './agreements';
export * from './time';
export * from './prices';
export * from './languages';
export * from './common';
export * from './affiliate_client';

// Convert currency
export const convertPrice = (value) => {
	if (isNaN(value)) return '';
	const price = value / 100;
	return price.toFixed(2);
};

export const createNavList = (categories, slugAll = '') => [
	{ slug: slugAll, name: 'Wszystkie', id: 'all' },
	{ slug: 'ulubione', name: 'Ulubione', id: 'favourite' },
	...categories
];

// Set channel logo
export const setChannelLogo = (images) => {
	if (images && images.logo && images.logo.length > 0)
		return images.logo[0].url;
	else if (images && images.logo_inverted.length > 0)
		return images.logo_inverted[0].url;
};

export const setValidChannelLogo = (images) => {
	let imgUrl = '';
	let isImgUrlValid = true;

	if (images && images.logo && images.logo?.length > 0) {
		imgUrl = images.logo[0].url;
	} else if (images && images.logo_inverted?.length > 0) {
		imgUrl = images.logo_inverted[0].url;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl, isImgUrlValid };
};

export const setChannelInverseLogo = (image) => {
	let imgUrl = '';
	let isImgUrlValid = true;

	if (image && image.logo_inverted && image.logo_inverted.length > 0) {
		imgUrl = image.logo_inverted[0].url;
	} else if (image && image.logo && image.logo.length > 0) {
		imgUrl = image.logo[0].url;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl, isImgUrlValid };
};

// Set image url
export const setImgUrl = (url) => {
	if (url && url.match(/^(https:\/\/)/)) {
		return url.split('?')[0];
	} else if (url && url.match(/^(http:\/\/)/)) {
		return url.split('?')[0];
	} else if (url) {
		return `https:${url.split('?')[0]}`;
	}
};

// Check preview img url
export const getImgUrl = ({
	images,
	width = 400,
	height = 350,
	isLive = true,
	onlyCover = false
}) => {
	let imgUrl = '';
	let isImgUrlValid = true;

	if (images && images.cover && images.cover.length > 0) {
		imgUrl = images.cover[0].url;
	} else if (
		!onlyCover &&
		images &&
		images.slides &&
		images.slides.length > 0 &&
		isLive
	) {
		imgUrl = `${setImgUrl(images.slides[0].url)}${setImgSizePath(
			width,
			height
		)}`;
	} else if (!onlyCover && images && images.logo && images.logo.length > 0) {
		imgUrl = images.logo[0].url;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl: imgUrl, isImgUrlValid };
};

export const checkPosterUrl = ({
	images,
	slides,
	width = 400,
	height = 350
}) => {
	let imgUrl = '';
	let isImgUrlValid = true;
	const posterUrl = images?.poster?.[0]?.url;
	const coverUrl = images?.cover?.[0]?.url;

	if (posterUrl) {
		imgUrl = posterUrl;
	} else if (coverUrl) {
		imgUrl = coverUrl;
	} else if (slides) {
		imgUrl = `${setImgUrl(slides)}${setImgSizePath(width, height)}`;
	} else {
		isImgUrlValid = false;
	}

	return { imgUrl, isImgUrlValid };
};

/**
 * returns poster image or error image
 * @param {object} images
 * @return {*}
 */
export function getPosterImage(images) {
	if (
		typeof images === 'object' &&
		!!images &&
		images.poster &&
		images.poster.length > 0 &&
		!!images.poster[0].url
	) {
		return images.poster[0].url;
	}

	return NoImageSVG;
}

// Create image sizePath
export const setImgSizePath = (width = 400, height = 350) =>
	`?srcmode=4&srcw=${width}&srch=${height}&dstw=${width}&dsth=${height}&type=1&quality=80`;

// CreateLink params
export const createLinkParams = ({
	id,
	name = 'produkt',
	hash = 'program',
	type = 'program',
	title = '',
	seriesUuid,
	seriesTitle,
	url = ''
}) => {
	const isHome = name === HOME;
	const pageName = isHome ? PRODUCT : name;
	const productType = seriesUuid ? SERIES : type;
	const productUuid = seriesUuid ?? id;
	const productTitle = seriesTitle ?? title;
	const slugTitle = getSlug(productTitle);
	const typeName = PRODUCT_TYPES_MAPPED[productType];

	switch (type) {
		case CHANNEL_EPG:
		case CHANNEL:
			return {
				pathname: `/tv/kanaly/szczegoly-programu/${id}`,
				hash
			};

		case VOD:
		case SERIES:
		case SEASON:
		case EPISODE:
		case COLLECTION:
		case ARTICLE:
		case LIVE_EVENT:
		case PASS:
		case PACKET:
		case VOUCHER_TEMPLATE:
			return {
				pathname: `/strona/${pageName}/${typeName}/${productUuid}/${slugTitle}`
			};

		case PROVISION:
			return {
				pathname: `/pakiety/pakiet/${id}/${slugTitle}`
			};

		case BANNER:
			return url;

		// program
		default:
			return {
				pathname: `/tv/kanaly/szczegoly-programu/${id}`,
				hash
			};
	}
};

// Get search parametr
export const getSearchParametr = (search, paramName) => {
	const searchParams = new URLSearchParams(search);
	return searchParams.get(paramName) || false;
};

// Set search parametr
export const setParametr = (name, value = '') => {
	const searchParams = new URLSearchParams();
	searchParams.set(name, value);
	return searchParams.toString();
};

// Check if image is exist
export const isImage = (images, sizeName) =>
	images &&
	images[sizeName] &&
	images[sizeName].length > 0 &&
	images[sizeName][0].url;

// required types to translate links
export const urlTranslationTypes = {
	regulamin: 'regulation',
	regulation: 'regulamin',
	'polityka-prywatnosci': 'privacy-policy',
	'privacy-policy': 'polityka-prywatnosci',
	'o-nas': 'about-us',
	'about-us': 'o-nas',
	strona: 'page',
	page: 'strona',
	logowanie: 'login',
	login: 'logowanie',
	home: 'strona-glowna',
	'strona-glowna': 'home',
	kanaly: 'channels',
	channels: 'kanaly',
	'szczegoly-programu': 'program-details',
	'program-details': 'szczegoly-programu',
	pakiety: 'packets',
	packets: 'pakiety',
	filmy: 'movies',
	movies: 'filmy',
	seriale: 'series',
	series: 'serial',
	'dla-dzieci': 'kids',
	kids: 'dla-dzieci',
	kolekcje: 'collection',
	collection: 'kolekcje',
	packet: 'pakiet',
	pakiet: 'packet',
	main: 'pakiet',
	vod: 'vod',
	epg: 'epg',
	sekcja: 'section',
	section: 'sekcja',
	szukaj: 'search',
	search: 'szukaj',
	wszystkie: 'all',
	all: 'wszystkie',
	program: 'programy',
	user: 'uzytkownik',
	uzytkownik: 'user',
	'historia-platnosci': 'payments',
	payments: 'historia-platnosci',
	profil: 'general',
	general: 'profil',
	urzadzenia: 'devices',
	devices: 'urzadzenia',
	nagrania: 'recordings',
	recordings: 'nagrania',
	'aktywne-wypozyczenia': 'products',
	products: 'aktywne-wypozyczenia',
	ulubione: 'favourites',
	favourites: 'ulubione',
	programs: 'programy',
	programy: 'programs',
	movie: 'film',
	film: 'movie',
	odcinek: 'episode',
	episode: 'odcinek',
	sezon: 'season',
	season: 'sezon',
	rodo: 'rodo',
	article: 'artykul',
	artykul: 'article'
};

// required categories to translate search links
const urlTranslationCategories = {
	kanaly: 'channel',
	channel: 'kanaly',
	all: 'wszystkie',
	wszystkie: 'all',
	seriale: 'series',
	series: 'seriale',
	filmy: 'vod',
	vod: 'filmy',
	program: 'programy',
	programy: 'program',
	live_event: 'live',
	live: 'live_event',
	article: 'artykul',
	artykul: 'article',
	audio: 'audio'
};

// get translated type
export const getTypeTranslation = (type) => urlTranslationTypes[type];

// get translated search category
export const getSearchCategory = (type) => urlTranslationCategories[type];

// check if the pin.length === 4 and contains only numbers
export const checkPinFormat = (pin) => {
	const isValid = pin.toString().length === 4 && pin.match(/^\d+$/);
	return isValid;
};

// get app name based on env file
export const getAppName = () => {
	const { TV_SMART, TV_ONLINE } = APP_PROJECT_TYPES;
	const appName =
		process.env.REACT_APP_PROJECT === TV_SMART ? TV_SMART : TV_ONLINE;

	return appName.toLowerCase();
};

// check if app type === tv smart
export const checkAppType = () => {
	const { TV_SMART } = APP_PROJECT_TYPES;
	const appName = getAppName();
	const isTvSmart = appName === TV_SMART.toLowerCase();

	return { isTvSmart, appName };
};

// get StyledBorderButton hover background gradient
export const getButtonHoverGradient = (background = null, border = null) => {
	const { isTvSmart } = checkAppType();

	const tvonlineBackground = background || backgroundHovered;
	const tvonlineBorder = border || borderHovered;

	const backgroundHover = isTvSmart
		? smartBackgroundHovered
		: tvonlineBackground;
	const borderHover = isTvSmart ? smartBorderHovered : tvonlineBorder;

	return { backgroundHover, borderHover };
};

// check player type based on url
export const checkPlayerType = (pathname) => {
	const { WATCH, PROGRAM_DETAILS, HOME } = ROUTES_CORE_PATHS;
	const { PLAYER, DETAILS, VOD } = PLAYER_SELECTORS;

	if (pathname.includes(WATCH)) {
		return VOD;
	} else if (pathname.includes(PROGRAM_DETAILS)) {
		return DETAILS;
	} else if (pathname.includes(HOME)) {
		return PLAYER;
	}

	return null;
};

// check if there is UUID in URL
export const containsUUID = (string) => {
	const regexID =
		/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
	return regexID.test(string);
};

// Find proper priceId by payment method in all payments list
export const getPriceIdByMethod = (paymentMethod, paymentOptions) => {
	// find payment
	const selectedPayment = paymentOptions.find(
		(payment) => payment.payment_method === paymentMethod
	);
	// return selected payment id
	return selectedPayment?.id;
};

// check if postal code is valid
export const checkPostalCodeValidate = ({ postal_code, regex }) => {
	return postal_code.match(regex);
};

export const checkPolishPostalCodeValidation = ({ postal_code }) => {
	const isValid = checkPostalCodeValidate({
		postal_code,
		regex: POSTAL_CODES_REGEX.POLAND
	});

	return isValid && postal_code.includes('-') && postal_code.length === 6;
};

export const checkIsDesktopOnly = () => {
	const { REACT_APP_DESKTOP_ONLY } = process.env;
	const isDesktopOnly = REACT_APP_DESKTOP_ONLY === 'true';

	return isDesktopOnly;
};

export const getInstitutionFromStorage = () => {
	const institution = sessionStorage.getItem(SESSIONSTORAGE_INSTITUTION);

	return JSON.parse(institution);
};

export const replaceStringChar = ({
	string = '',
	regex = null,
	object = null,
	splitBy = '',
	joinBy = ''
}) => {
	if (regex && object) {
		return string.replace(regex, (char) => object[char]);
	}

	return string.split(splitBy).join(joinBy);
};

export const getPreviewAssetId = (previews) => {
	const isPreview = previews?.preview?.length;

	if (isPreview) {
		return previews.preview[0].videoAssetId;
	}

	return '';
};

// Parse font size
export const parseFontSize = (size) => Number(size.toFixed(1));

export const isNameSurnameValid = (string) => {
	const nameSurnameRegex =
		/^[^<>!@#$%^&*()=+1234567890? ,.:;"|}{_`~§£[\]\\/]*$/;
	return nameSurnameRegex.test(string);
};

// Get user agent
export const getUserAgentInfo = () => {
	const parser = new UAParser();
	const uastring = window.navigator.userAgent;

	const match =
		/iphone|ipad|ipod|android|webOS|opera mini|blackBerry|windows Phone/;
	const matchiOS = /iphone|ipad|ipod/;
	const matchIphone = /iphone/;

	const userAgent = uastring.toLowerCase();
	const isMobile = match.test(userAgent);
	const ios = matchiOS.test(userAgent);
	const isIphone = matchIphone.test(userAgent);

	const { browser, os } = parser.setUA(uastring).getResult();
	const isSafari = browser.name.toLowerCase() === 'safari' || ios;

	const mobileType = ios ? 'ios' : 'android';

	const deviceVersion = os?.version || uuidv4();
	const deviceFullName = os?.name ? `${os?.name} v.${deviceVersion}` : uuidv4();

	return {
		isMobile,
		mobileType,
		isSafari,
		browser,
		os,
		isIOS: ios,
		isIphone,
		deviceFullName
	};
};

export const getRenderAsLink = (isLink, isAnchor) =>
	isAnchor ? 'a' : isLink ? Link : 'button';

export const getInappropriateContentTypes = (object) =>
	Object.keys(object).filter((key) => object[key]);
